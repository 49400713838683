import { useEffect, useState } from 'react'
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { Address, OpenedContract, toNano, Sender, SenderArguments, beginCell, SendMode, Slice, Cell } from '@ton/core';
import { utils, writeFile } from "xlsx";
import { Button, Empty, message } from 'antd';
import styled from '@emotion/styled';
import { JettonMaster, TonClient, WalletContractV4 } from "@ton/ton";
import { BatchCreateWalletContent, Import, SenderContent, SenderPage, Table, Text } from './style';
import { getHttpEndpoint } from '@orbs-network/ton-access';
import { JettonTransfer, MutiTransferContract as MuliTransferContract, PreJettonTransfer, storeJettonTransfer, storePreJettonTransfer } from './counter';
import { mnemonicNew, mnemonicToPrivateKey } from "ton-crypto";
import * as XLSX from 'xlsx';
import TonWeb, * as tonweb from "tonweb";
import { Dictionary, } from '@ton/core';
import { FolderAddOutlined } from '@ant-design/icons';
import CopyToClipboard from 'react-copy-to-clipboard';
import { get } from 'http';
import Model from './Model'

// const ContractAddress = "kQDz68jQ8yTKv0wDniSO4_0SHCWsGOS6xZThiBCeax0diz_B" 
// const ContractAddress = "kQD6s4FxS6sussVGLtp4H3BaIfsu_DQl8U8jfuc4cZs8YIPA"

const ContractAddress = "EQD6s4FxS6sussVGLtp4H3BaIfsu_DQl8U8jfuc4cZs8YDhK"
const Page = styled.div`
  min-height:70vh;
  font-size: 20px;
  margin-top: 200px;
  text-align: center;

  .ant-segmented-group {
    height: 46px;
    border: 0.5px solid rgba(114, 138, 150, 0.16);
  }
  .ant-segmented-item-label {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .input {
    margin: 20px 0;
    input {
      height: 44px;
    }
  }
  .btn {
    margin-top: 40px;
    background: linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%);
    border-radius:6px;
    line-height:44px;
    color:#FFF;
    cursor:pointer;
  }
  @media screen and (max-width:1200px){
    font-size: 16px;
    width:98%;
    margin:100px auto;

  }
`
interface WalletInfo {
  address: string;
  mnemonics: string;
}
function sleep(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}
function Sendertoken() {
  const wallet = useTonAddress();
  const [balance, setBalance] = useState(0)


  const [selected, setSelected] = useState(1)
  const [onFile, setOnFile] = useState(true)
  const [wallets, setWallets] = useState<WalletInfo[]>([]);
  const [quantity, setQuantity] = useState<number>(0);
  const [tonConnectUI, setOptions] = useTonConnectUI();
  const [tokenAddress, setTokenAddress] = useState('')
  // const [values, setValues] = useState('')
  const [values, setValues] = useState('')
  const tokenAddressChange = (e: any) => {
    setTokenAddress(e.target.value)
  }
  const valuesChange = (e: { target: { value: any; }; }) => {
    const new_values = e.target.value;
    setValues(new_values);
    const addressAndAmountPairs = new_values.split(/[\r\n]+/).map((line: string) => line.split(/,|，/)).filter((pair: string | any[]) => pair.length === 2);
    const addresses = addressAndAmountPairs.map((pair: string[]) => pair[0].trim());
    setTokenArr(addresses);
  };
  const client = useTonClient();


  const [addr, setAddr] = useState("")

  const counterContract = useAsyncInitialize(async () => {
    if (!client) return;
    const contract = new MuliTransferContract(
      Address.parse(ContractAddress)
    );
    return client.open(contract) as OpenedContract<MuliTransferContract>;
  }, [client]);

  const [tokenArr, setTokenArr] = useState([]); // 存储 token 地址数组
  const [requiredTon, setRequiredTon] = useState(''); // 存储计算结果

  // 计算并更新 requiredTon 状态
  useEffect(() => {
    if (tokenArr.length > 0) {
      const amount = ((tokenArr.length) * 0.05) + 2;
      setRequiredTon(amount.toString());
    } else {
      setRequiredTon('0');
    }
  }, [tokenArr]);


  useEffect(() => {
    async function getValue() {
      if (!counterContract) return;
      console.log("null")
      const val = await counterContract.getGetFeeGeter();
      setAddr(val.toString())
    }

    getValue();
  }, [counterContract]);



  const sendTon = () => {
    console.log("发送ton11111")
    if (!counterContract) return;
    try {
      const tokenArr: any[] = []
      const amountArr: any[] = []
      const _arr = values.split(/[(\r\n)\r\n]+/)
      _arr.forEach((item, index) => {
        const arr = item.split(/[,，]+/)
        tokenArr.push(arr[0])
        amountArr.push(arr[1])
      })

      console.log(tokenArr, amountArr)
      const sum = amountArr.reduce((acc, curr) => {
        return Number(acc) + (typeof Number(curr) === 'number' ? Number(curr) : 0);
      }, 0);

      console.log("数组总和为：" + sum); // 输出：数组总和为：15


      const amounts = Dictionary.empty<bigint, bigint>();
      amountArr.forEach((amount, index) => {
        amounts.set(BigInt(index + 1), toNano(amount));
      })
      console.log(amounts, 'myDictionary')


      const accounts = Dictionary.empty<bigint, Address>();
      tokenArr.forEach((tokenAddress, index) => {
        accounts.set(BigInt(index + 1), Address.parse(tokenAddress));
      });
      console.log(accounts, 'myDictionary')


      const w: Sender = {
        address: Address.parse(wallet),
        send: async (args: SenderArguments) => {


          console.log("Transaction details:", args.to.toString());

          await tonConnectUI.sendTransaction({
            validUntil: Math.floor(Date.now() / 1000) + 360, // 设置交易的有效时间
            messages: [
              {
                address: args.to.toString(),  // 发送到的目标地址
                amount: args.value.toString(),  // 发送的金额
                payload: args.body?.toBoc().toString('base64')  // 可选：交易消息体
              }
            ],
          });

          console.log("Transaction sent successfully!");
        }
      }


      console.log(w, 'w')
      counterContract.send(
        w,
        {
          value: toNano(sum + 2),
        },
        {
          $$type: 'MutiTransfer',
          amounts: amounts,
          accounts: accounts

        },
      );

    } catch (error: any) {
      message.error(error.toString())
    }



  }



  async function sendToken() {
    console.log("发送tdaibi")

    const nowbalance = await client!.getBalance(Address.parse(wallet))
    console.log(nowbalance)
    if (!counterContract) return;
    if (!tokenAddress) return message.error("请填入代币地址")

    try {
      const tokenArr: any[] = []
      const amountArr: any[] = []
      const _arr = values.split(/[(\r\n)\r\n]+/)
      _arr.forEach((item, index) => {
        const arr = item.split(/[,，]+/)
        tokenArr.push(arr[0])
        amountArr.push(arr[1])
      })

      console.log(tokenArr, amountArr)
      const sum = amountArr.reduce((acc, curr) => {
        return Number(acc) + (typeof Number(curr) === 'number' ? Number(curr) : 0);
      }, 0);

      console.log("数组总和为：" + sum);
      if (nowbalance < toNano(((tokenArr.length) * 0.05) + 2)) {
        alert(`请先预留足够gas\n目前批量${tokenArr.length}个地址\n需要预留 ${((tokenArr.length) * 0.07) + 2} Ton,未使用完的将会退回`)
        return;
      }
      if (tokenArr.length > 100) {
        alert(`超出限制,单次只允许发送一百个地址`)
        return;
      }
      const jettonMasterAddress = Address.parse(tokenAddress)

      const jettonMaster = client?.open(JettonMaster.create(jettonMasterAddress))

      const ContractjettonWallet = await jettonMaster?.getWalletAddress(Address.parse(ContractAddress))
      const UserjettonWallet = await jettonMaster?.getWalletAddress(Address.parse(wallet))


      const decimals = await fetch(`https://toncenter.com/api/v3/jetton/masters?address=${tokenAddress}&limit=10&offset=0`, {
        method: 'GET',
        headers: {
          'accept': 'application/json'
        }
      })
        .then(response => response.json()) // 将响应解析为 JSON 格式
        .then(data => {
          console.log(data.jetton_masters[0].jetton_content.decimals, ' data.jetton_masters[0].jetton_content.decimal')
          return data.jetton_masters[0].jetton_content.decimals
        })
        .catch(error => {
          console.error('Error:', error);
        });
      console.log(sum * Math.pow(10, decimals), 'sum * Math.pow(10, decimals)')
      console.log(ContractjettonWallet?.toString(), "ContractjettonWallet")
      console.log(UserjettonWallet?.toString(), "UserjettonWallet")




      const body = beginCell()
        .storeUint(0xf8a7ea5, 32)                 // jetton 转账操作码
        .storeUint(456434, 64)                         // query_id:uint64
        .storeCoins(sum * Math.pow(10, decimals))                      // amount:(VarUInteger 16) -  转账的 Jetton 金额（小数位 = 6 - jUSDT, 9 - 默认）
        .storeAddress(Address.parse(ContractAddress))                 // destination:MsgAddress
        .storeAddress(Address.parse(wallet))                 // response_destination:MsgAddress
        .storeUint(0, 1)                          // custom_payload:(Maybe ^Cell)
        .storeCoins(1)                 // forward_ton_amount:(VarUInteger 16)
        .storeUint(0, 1)                           // forward_payload:(Either Cell ^Cell)
        .endCell();
      var tx = await tonConnectUI.sendTransaction({
        validUntil: Math.floor(Date.now() / 1000) + 3600, // 设置交易的有效时间
        messages: [

          {
            address: UserjettonWallet!.toString(),  // 发送到的目标地址
            amount: toNano("0.04").toString(),  // 这是要发送的 TON
            payload: body.toBoc().toString('base64'),  // 可选：交易消息体

          }
        ]
      }
      );


      console.log(tx)
      var hash = Cell.fromBase64(tx.boc)
      console.log(hash.hash().toString("base64"))

      const escapeIllegalChars = (input: string): string => {
        // 定义需要转义的字符映射表
        const escapeMap: { [key: string]: string } = {
          '%': '%25',
          '=': '%3D',
          '<': '%3C',
          '>': '%3E',
          '/': '%2F',
          '+': '%2B',
        };

        // 使用正则表达式动态替换非法字符
        return input.replace(/[<>=%/+]/g, (char) => escapeMap[char]);
      };
      var formatHash = escapeIllegalChars(hash.hash().toString("base64"))
      await sleep(3000);
      var txresponse;
      const maxRetries = 8; // 最大重试次数
      let attempts = 0; // 当前重试次数

      while (attempts < maxRetries) {
        try {
          const response = await fetch(`https://tonapi.io/v2/events/${formatHash}`, {
            method: 'GET',
            headers: {
              'accept': 'application/json',
              'Accept-Language': 'ru-RU,ru;q=0.5'
            }
          });
          if (!response.ok) {
            console.log("Error fetching, retrying after 2 seconds...");
            await sleep(2000);
            attempts++;
            continue;
          }

          txresponse = await response.json();
          console.log('Received data:', txresponse);

          // 检查 status 是否为 'ok'
          if (txresponse.actions[0]?.status === "ok") {
            console.log('Valid transaction status:', txresponse.actions[0].status);
            break; // 成功退出循环
          } else {
            console.log('Transaction status is not ok, retrying after 2 seconds...');
            await sleep(2000);
            attempts++;
          }
        } catch (error) {
          console.error('Error fetching data from TON API:', error);
          attempts++;
        }
      }

      if (attempts === maxRetries) {
        console.error("Max retries reached. Exiting.");
      } else {
        console.log("Transaction successfully processed:", txresponse);
      }

      // 检查 amountArr 和 tokenArr 是否有值
      console.log("Amount Array:", amountArr);
      console.log("Token Array:", tokenArr);

      // 创建 amounts 字典
      const amounts = Dictionary.empty(Dictionary.Keys.BigInt(256), Dictionary.Values.BigInt(256));
      amountArr.forEach((amount, index) => {
        const amountInNano = BigInt(amount * Math.pow(10, decimals));
        amounts.set(BigInt(index), amountInNano);
      });

      // 创建 accounts 字典
      const accounts = Dictionary.empty(Dictionary.Keys.BigInt(256), Dictionary.Values.Address());
      tokenArr.forEach((tokenAddress, index) => {
        const parsedAddress = Address.parse(tokenAddress); // 解析 token 地址
        accounts.set(BigInt(index), parsedAddress);
      });

      const w: Sender = {
        address: Address.parse(wallet),
        send: async (args: SenderArguments) => {


          await tonConnectUI.sendTransaction({
            validUntil: Math.floor(Date.now() / 1000) + 3600, // 设置交易的有效时间
            messages: [
              {
                address: args.to.toString(),  // 发送到的目标地址可以是ton交易接收者，正在与合约交互则是合约地址
                amount: args.value.toString(),  // 这是要发送的 TON
                payload: args.body!.toBoc().toString('base64')  // 可选：交易消息体
              },
              // {
              //   address: UserjettonWallet!.toString(),  // 发送到的目标地址
              //   amount: toNano("0.04").toString(),  // 这是要发送的 TON
              //   payload: body.toBoc().toString('base64'),  // 可选：交易消息体

              // }
            ]
          });
          console.log("Transaction sent successfully!");
        }

      }
      await counterContract.send(
        w,
        {
          value: toNano(((tokenArr.length) * 0.05) + 2),
        },
        {
          $$type: "PreJettonTransfer",
          query_id: toNano(1),
          sender: Address.parse(wallet),
          amounts: amounts,
          accounts: accounts,
          walletAddress: ContractjettonWallet!
        }
      );

    } catch (error: any) {
      message.error(error.toString())
    }
  }


  ;

  const handleFileChange = (e: any) => {
    const file = e.target.files?.[0];
    if (!file) return;

    const reader = new FileReader();

    reader.onload = (e) => {
      const data = new Uint8Array(e.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json<any[]>(workbook.Sheets[sheetName], { header: 1 });
      const formatted = worksheet.slice(1).map((row: any[]) => `${row[0]},${row[1]}`).join('\n');

      setValues(formatted);
    };

    reader.readAsArrayBuffer(file);

    setOnFile(true);

  };

  // 选择上传文件还是手动输入
  const onchangeOnFile = (data: boolean) => {
    setOnFile(data);
    // console.log("aaaaaa");
    if (!data) {
      setValues('')

    }
  };


  // 点击下载模板
  const handleDownloadTemplate = () => {
    // 定义模板数据
    const templateData = [
      ["地址", "数量"],
      ["0QAdA75fp3ZYhuO9pWMsHoMD4xUbu1-MXMXtZXMlxH_urnhu", 1],
      ["0QC5vmbwV-QyPfz9XGTDtGRPtqDkqhEXFzXTXCImiKvCWDKM", 2]
    ];

    // 创建工作簿和工作表
    const wb = utils.book_new();
    const ws = utils.aoa_to_sheet(templateData);

    // 将工作表添加到工作簿中
    utils.book_append_sheet(wb, ws, "Template");

    // 导出 Excel 文件
    writeFile(wb, "template.xlsx");
  };

  const create = async (walletCount: number) => {
    const wallets = await createWallets(walletCount);
    console.log(wallets, 'wallets')
    setWallets(wallets)
  }

  // 导出csv
  const exportCSV = () => {
    // 创建一个新的工作簿
    const workbook = XLSX.utils.book_new();

    // 将数据转换为工作表
    const worksheetData = [
      ['钱包', '私钥'], // 标题
      ...wallets.map(wallet => [wallet.address, wallet.mnemonics])
    ];
    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData);

    // 将工作表添加到工作簿中
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Wallets');

    // 导出 Excel 文件
    XLSX.writeFile(workbook, 'wallets.xlsx');
  };


  // 复制函数
  const handleCopy = () => {
    message.success("Copied successfully");
  };

  const autoAdd = (amount: string) => {
    const tokenInfo: any[] = []
    const token = values.split(/[(\r\n)\r\n]+/)
    token.forEach((item) => {
      const arr = item.split(/[,，]+/)
      if (arr[0]) {
        const obj = { Address: arr[0], Amount: amount }
        tokenInfo.push(obj)
      }
    })

    const tokenInfo1: any[] = []
    tokenInfo.forEach((item) => {
      const str = `${item.Address},${item.Amount}`
      tokenInfo1.push(str)
    })
    const inputValue_ = tokenInfo1.join('\n')
    setValues(inputValue_)

  }


  return (
    <Page>
      <SenderPage>
        <div className={`tab ${selected === 0 ? "active" : ""}`} onClick={() => setSelected(0)}>
          TON发送
        </div>
        <div className={`tab ${selected === 1 ? "active" : ""}`} onClick={() => setSelected(1)}>
          其他代币发送
        </div>
        <div className={`tab ${selected === 2 ? "active" : ""}`} onClick={() => setSelected(2)}>
          批量创建钱包
        </div>
      </SenderPage>
      <SenderContent>
        {selected === 1 && (
          <>
            <div className='content'>
              <div style={{ textAlign: "start", color: '#fff' }}>输入代币地址</div>
              <input value={tokenAddress} onChange={tokenAddressChange} />
            </div>

            <p>发送交易需要确认两次，中间等待节点需要时间，耐心等待不要做多余操作，仔细确认，否则转账失败损失自行负责</p>
            <p>每个发送地址预留了0.05Ton用作Gas,发送完成每个地址的多余Gas都会退回钱包</p>
            <h2>钱包内需求Gas预留： {requiredTon} ton , 未使用完退回钱包</h2>
          </>
        )}
        {selected !== 2 && (
          <>
            <p>收款地址和数量（每行应包括地址和数量，英文逗号分隔）,每次提交限制一百个地址</p>
            {/* <div className='btn'>自动添加数量</div> */}
            <Model callBack={autoAdd} />
            {onFile ?
              <div className='content'>
                <textarea value={values} onChange={valuesChange}
                />
              </div>
              :
              <>
                {values.length === 0 ? (
                  <div className='upLoadFile'>
                    <div><FolderAddOutlined style={{ width: "100px", height: "100px", color: "grey" }} />
                      <div>{("upload files")}</div>
                    </div>
                    <input style={{ opacity: "0", width: "100%", height: "100%" }} type="file" onChange={handleFileChange} />
                  </div>
                ) : (
                  <textarea
                    className="custom-textarea"
                    onChange={(e) => setValues(e.target.value)}
                  />
                )}
                <p style={{ textAlign: "left" }}>
                  支持文件类型：Excel
                </p>
                <p onClick={handleDownloadTemplate} style={{ cursor: "pointer" }}>
                  下载模板
                </p>

              </>}


            {onFile ? <div className='upload' onClick={() => onchangeOnFile(false)}>上传文件</div> : <div className='upload' onClick={() => { onchangeOnFile(true); }}>手动输入</div>}

            <div className='btn' onClick={selected ? sendToken : sendTon}>
              发送
            </div>
          </>
        )}

        {selected === 2 && (

          <>
            <BatchCreateWalletContent>
              <Text>
                <span>{("Enter quantity")}</span>
                <input style={{ background: "#15191D", flex: 1, color: "#FFF", outline: "none" }}
                  type="number"
                  value={quantity}
                  onChange={(e) => setQuantity(Number(e.target.value))}
                  placeholder={("Enter quantity")}
                />

              </Text>
              <div style={{
                color: " #558484",
                marginTop: 20
              }}>
                {("Highly recommended: Disconnect from the internet before embarking on the creation of your wallet for utmost security.")}
              </div>
              <Import>
                <button onClick={() => create(quantity)}>
                  {("Generate wallet")}
                </button>
                <button onClick={exportCSV}>
                  {("Export Excel")}
                </button>
              </Import>
              <Table>
                <div className="tableHead">
                  <span>{("Wallet")}</span>
                  <span>{("private key")} </span>
                </div>
                <div className="tableBody">
                  {wallets.map((wallet, index) => (
                    <div key={index}>
                      <div>
                        <CopyToClipboard text={wallet.address} onCopy={() => handleCopy()}>
                          <span>{wallet.address}</span>
                        </CopyToClipboard>
                      </div>
                      <div>
                        <CopyToClipboard text={wallet.mnemonics} onCopy={() => handleCopy()}>
                          <span>{wallet.mnemonics}</span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  ))}
                </div>
              </Table>
            </BatchCreateWalletContent >
          </>
        )}
      </SenderContent>
    </Page>
  )
}

const client = new TonClient({
  endpoint: 'https://toncenter.com/api/v2/jsonRPC',
});
export async function createWallets(count: number) {
  const wallets = [];
  for (let i = 0; i < count; i++) {
    let mnemonics = await mnemonicNew();
    console.log(mnemonics, 'mnemonics')
    const formattedMnemonics = mnemonics.join(' ')
    let keyPair = await mnemonicToPrivateKey(mnemonics);
    let workchain = 0;
    let wallet = WalletContractV4.create({ workchain, publicKey: keyPair.publicKey });
    let contract = client.open(wallet);
    console.log(contract.address);

    wallets.push({
      address: contract.address.toString(),
      mnemonics: formattedMnemonics
    });
  }
  return wallets;
}

export default Sendertoken
export function useTonClient() {

  return useAsyncInitialize(
    async () =>
      new TonClient({
        endpoint: await getHttpEndpoint({ network: 'mainnet' }),
      })
  );

}
export function useAsyncInitialize<T>(func: () => Promise<T>, deps: any[] = []) {
  const [state, setState] = useState<T | undefined>();
  useEffect(() => {
    (async () => {
      setState(await func());
    })();
  }, deps);

  return state;
}