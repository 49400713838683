import { Box, styled } from "@mui/material";

const StyledContainer = styled(Box)({
  width: "100%",
  overflow: "auto",
  // background:"red",
  // height:144
});

const StyledInputContainer = styled(Box)(({ matches, error }: { matches: boolean, error: boolean })  => ({
  width: "100%",
  height: matches ? 40 : 57,
  display: "flex",
  alignItems: "center",
  // background: "#F7F9FB",
  border: " 1px solid #808080",
  borderRadius: 28,
  paddingRight: 5,
  transition: "0.2s all",
  "& .base-button": {
    height: "calc(100% - 10px)",
    padding: "0px 15px",
    fontSize: 12,
  },
}));

const StyledInput = styled("input")(({ theme }) => ({
  flex: 1,
  height: "100%",
  border: "unset",
  background: "transparent",
  outline: "none",
  color: "#fff",
  fontFamily: "Mulish",
  fontSize: 20,
  paddingLeft: 29,
  caretColor: "#728A96",
  "&::placeholder": {
    color: "#999999",
    fontFamily: "Mulish",
    transition: "0.2s all",
  },
  "&:focus": {
    "&::placeholder": {
      opacity: 0,
    },
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,

  },
}));

export { StyledInput, StyledInputContainer, StyledContainer };
