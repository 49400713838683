import { Box, styled, Typography } from "@mui/material";
import theme from "theme";

const RowMessage = styled(Box)(({ theme, type }: { theme: any; type: string }) => ({
  maxWidth: "90%",
  display: "flex",
  alignItems: "flex-start",
  gap: 5,
  paddingLeft: 20,
  textAlign: "justify",

  marginTop: 11,
  "& *": {
    color: type === "success" ? "#0EA438" : "#EEBD40",
  },
  "& svg": {
    color: type === "success" ? "#0EA438" : "#EEBD40",
    width: 16,
    position: "relative",
    top: -3,
  },
  "& p": {
    margin: 0,
    "& a": {
      textDecoration: "none",
      fontWeight: 800,
    },
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: 14
  },
}));


const RowActionsButton = styled(Box)(({ theme }) => ({
  height: "100%",
  display: "flex",
  alignItems: "center",
  gap: 10,
  "& .base-button": {
    height: "calc(100% - 16px)",
    marginRight: 5,
    padding: "0px 10px",
    fontSize: 20,
    fontWeight: 700,
    background: "#0088CC",
    color: "#fff",
    borderRadius: 24,
    "&:hover": {
      background: "rgb(0, 95, 142)",
    },
  },
  [theme.breakpoints.down("sm")]: {
    "& .base-button": {
      fontSize: 16,
      height: "calc(100% - 8px)",

    }
  },
}));

const RowValueDisplayer = styled(Box)(({ theme }) => ({
  height: 57,
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "0px 5px 0px 20px",
  borderRadius: 40,
  border: "1px solid #808080",
  [theme.breakpoints.down("sm")]: {
    height: 35,
  },
}));

const RowTitle = styled(Typography)(({ theme }) => ({
  fontSize: 20,
  color: "#FFF",
  fontWeight: 500,
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(2.5),
  [theme.breakpoints.down("sm")]: {
    fontSize: 16,
  },
}));

const RowContent = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  width: "100%",
  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

const RowValueSection = styled(Box)(({ hasButton }: { hasButton?: boolean }) => ({
  width: hasButton ? "calc(100% - 140px)" : "100%",
  display: "flex",
  alignItems: "center",
  "& .address-link": {},
  "& p": {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: "#FFF",
    fontSize: 20,
    paddingRight: 20,
    [theme.breakpoints.down("sm")]: {
      fontSize: 16,
    },
  },
}));

export { RowMessage, RowTitle, RowContent, RowValueDisplayer, RowValueSection, RowActionsButton };
