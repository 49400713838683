import { Typography, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  zeroPadding?: boolean;
}

//TODO replace with generic AppHeading

function FieldDescription({ children, zeroPadding }: Props) {
  const matches = useMediaQuery("(max-width:599px)");

  return (
    <Typography
      style={{ fontSize: matches ? 14 : 20, marginTop: 5, paddingLeft: zeroPadding ? 0 : 18, paddingRight: zeroPadding ? 0 : 18,color: "#7689AE", lineHeight: matches ? "16px" : "60px" }}>
      {children}
    </Typography>
  );
}

export default FieldDescription;
