import React from "react";
import { Box, Link, styled } from "@mui/material";

interface IconProps {
  iconUrl: string;
  hoveredIconUrl: string;
  disabled?: boolean;
}

const Icon = styled(Box)((props: IconProps) => ({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: theme.spacing(8),
  height: theme.spacing(8),
  background: `url(${props.iconUrl})`,
  backgroundSize: "cover",
  "&:hover": {
    transitionDuration: ".25s",
    background: `url(${props.disabled ? props.iconUrl : props.hoveredIconUrl})`,
    backgroundSize: "cover",
    cursor: props.disabled ? "cursor" : "pointer",
  },
  [theme.breakpoints.down("md")]: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
}));

interface HoverableIconProps extends IconProps {
  link: string;
}

export const HoverableIcon: React.FC<HoverableIconProps> = ({ iconUrl, hoveredIconUrl, link }) => {
  return !link.length ? (
    <Icon  iconUrl={iconUrl} hoveredIconUrl={hoveredIconUrl} disabled />
  ) : (
    <Link rel="nofollow"  target="_blank" href={link} >
      <Icon iconUrl={iconUrl} hoveredIconUrl={hoveredIconUrl} />
    </Link>
  );
};
