import { Box, colors, styled, Typography } from "@mui/material";

const StyledForm = styled("form")(({ theme }) => ({
  position: "relative",

  padding: "46px 76px",
  [theme.breakpoints.down("md")]: {
    padding: "0",
  },
  // overflow: "hidden",
}));
const logoInput = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 17,
  [theme.breakpoints.down("md")]: {
    margin: "0",
  },
}));
const StyledFormInputs = styled(Box)(({ theme }) => ({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  gap: 17,
  [theme.breakpoints.down("md")]: {
    margin: "0",
  },
}));
const LogoLink = styled(Box)(({ theme }) => ({
  margin: "20px 0",
  lineHeight: "55px",
  borderRadius: "28px",
  display: "flex",
  "input": {
    lineHeight: "55px",
    border: "1px solid #808080",
    outline: "none",
    width: "80%",
    paddingLeft: "29px",
    background: "transparent",
    fontSize: 20,
    color: "#FFFF",
    borderRadius: "29px 0px 0px 29px",

  },
  "input::placeholder": {
    color: "#989898"
  },
  [theme.breakpoints.down("md")]: {
    lineHeight: "38px",
    "input": {
      fontSize: 16,
      lineHeight: "38px",


    },
  },
}));
const LogoLinkButton = styled(Box)(({ theme }) => ({
  flex: "1",
  background: "linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%)",
  borderRadius: " 0px 29px 29px 0px",
  textAlign: "center",
  fontFamily: " Microsoft YaHei",
  fontWeight: "400",
  fontSize: "20px",
  color: "#FFFFFF",
  cursor: "pointer",
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  },
}));

const StyledActionBtn = styled(Box)(({ theme }) => ({
  marginTop: 30,
  marginBottom: 10,
  marginLeft: "auto",
  marginRight: "auto",
  width: "100%",
  "& .base-button": {
    width: "100%",
    fontSize: 20,
    background: " linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%)"
  },
  [theme.breakpoints.down("md")]: {
    "& .base-button": {
      fontSize: 16,
    },
  },
}));

const JettonFormTitle = styled(Typography)(({ theme }) => ({
  color: "#FFF",
  fontSize: 26,
  fontWeight: 800,
  marginBottom: 0.5,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  maxWidth: 300,
  [theme.breakpoints.down("md")]: {
    // padding: "0",
    fontSize: 20,

  },
}));

export { logoInput, StyledForm, StyledFormInputs, StyledActionBtn, JettonFormTitle, LogoLink, LogoLinkButton };
