import { t } from "i18next"

function App() {
  return (
    <>
      <h2>{t("Steps")}</h2>
      <p>{("Click to enter the address for receiving test coins")}:<a rel="nofollow" href="https://t.me/testgiver_ton_bot">https://t.me/testgiver_ton_bot</a> ，{t("Click 'Send Message'")}</p>
      <div className="images">
        <img src="/images/test5.webp" alt="test" />
      </div>
      <p>{t("Enter the Telegram dialog box and click 'Start' at the bottom")}</p>
      <div className="images especial">
        <img src="/images/test4.webp" alt="test" />
      </div>
      <p>{t("You will receive a message and select 'Get 5 TON intestnet'")}</p>
      <div className="images">
        <img src="/images/test2.webp" alt="test" />
      </div>
      <p>{t("Enter the verification code in the picture and send")}</p>
      <div className="images">
        <img src="/images/test3.png" alt="test" />
      </div>
      <p>{t("Copy your wallet address, send it, and you’re done")}</p>
      <div className="images">
        <img src="/images/test1.png" alt="test" />
      </div>
      <h3></h3>
    </>
  )
}
export default App