import { getNetwork } from "./lib/hooks/useNetwork";
const ROUTES = {
  deployer: "/",
  jetton: "/jetton",
  jettonId: "/jetton/:id",
};

const APP_GRID = 1200;

const LOCAL_STORAGE_PROVIDER = "wallet_provider";

const APP_DISPLAY_NAME = "TON TOOL";

const JETTON_DEPLOYER_CONTRACTS_GITHUB = "https://github.com/ton-blockchain/minter-contract";

const EXAMPLE_ADDRESS =
  getNetwork(new URLSearchParams(window.location.search)) === "testnet"
    ? "EQBP4L9h4272Z0j_w9PE2tjHhi8OwkrRbTmatKszMyseis05"
    : "EQD-LkpmPTHhPW68cNfc7B83NcfE9JyGegXzAT8LetpQSRSm";

const SEARCH_HISTORY = "searchHistory";

// const YAGONG = '0QAzHkpeIQTPRGaBs_ITmEAB5shrir5cw2-1c5FQVw1UUfpg'
const YAGONG =
  getNetwork(new URLSearchParams(window.location.search)) === "testnet"
    ? "0QAzHkpeIQTPRGaBs_ITmEAB5shrir5cw2-1c5FQVw1UUfpg"
    : "UQCNY0SBU6GsTnicSSLMXe5njDPIxY5SdQJp73VlDd9AsNat";

export {
  ROUTES,
  LOCAL_STORAGE_PROVIDER,
  APP_GRID,
  JETTON_DEPLOYER_CONTRACTS_GITHUB,
  APP_DISPLAY_NAME,
  EXAMPLE_ADDRESS,
  SEARCH_HISTORY,
  YAGONG
};
