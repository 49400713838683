import { Box, styled, Typography } from "@mui/material";
import { border, borderRadius, minHeight, positions } from "@mui/system";
// import styled from 'styled-components'
export const SenderPage = styled(Box)(({ theme }) => ({
  background: "#15191D",
  lineHeight: "60px",
  display: "flex",
  borderRadius: 6,
  color: "#FFF",
  ".tab": {
    padding: "0 20px",
    cursor: "pointer"
  },
  ".tab.active": {
    background: " #192C47",
    borderRadius: "6px",
  },
}))
export const SenderContent = styled(Box)(({ theme }) => ({
  background: "#15191D",
  borderRadius: 6,
  marginTop: 10,
  padding: "40px 60px",
  ".content": {
    "textarea,input": {
      width: "100%",
      background: "#15191D",
      border: "1px solid #808080",
      lineHeight: 1.5,
      padding: 6,
      outline: "none",
      borderRadius: 10,
      color: "#FFF",
      minHeight:"300px"
    },
    input: {
      margin: "10px 0",
      borderRadius: 10,

    }

  },
  ".upload": {
    border: "1px solid",
    padding: "0 10px",
    width: 120,
    borderRadius: 4,
    color: "#808080",
    cursor: "pointer"
  },
  p: {
    textAlign: "start",
    color: "rgb(118, 137, 174)"
  },
  [theme.breakpoints.down("sm")]: {
    padding: "20px 15px",

  },
  ".upLoadFile": {
    borderRadius: 10,
    border: " 1px solid #727272",
    height: 262,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    textAlign: "center",
    color: "grey",
    "svg": {
      width: 100,
      height: 100,
    },
    "input": {
      cursor: "pointer",
      position: "absolute",
      top: 0,
      left: 0,
      height: 262,
    }
  }
}))
export const BatchCreateWalletContent = styled(Box)(() => ({
  borderRadius: 6,
  color:"#FFF",
  "table": {
    width: "100%",
    tableLayout: "fixed",
    minHeight: 200,
    borderRadius: 8,
    border: " 1px solid #737373",
    padding: 10,

    "thead": {
      height: 50,
      "th": {
        paddingLeft: 20
      }
    },
    "thead tr": {
      borderBottom: "1px solid #737373"
    },
    "td": {
      borderBottom: " 1px solid #737373",
      padding: 10,
      wordWrap: " break-word"
    }
  }
}))

export const Text = styled(Box)(() => ({

  display: "flex",
  lineHeight: "41px",
  "input": {
    marginLeft: 13,
    width: "100%",
    borderRadius: 5,
    border: "1px solid #737373"
  }
}))
export const Import = styled(Box)(() => ({
  marginTop: 23,
  display: "flex",
  justifyContent: "space-between",
  "button": {
    width: "45%",
    height: 42,
    background: "#D9EBEB",
    borderRadius: 5,
    fontSize: 16,
    fontWeight: 700,
  },
  "button:nth-child(1)": {
    background: "linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%)"
  }
}))
export const Table = styled(Box)(()=>({
  overflow:"auto",
  borderRadius: 8,
  border: "1px solid #737373",
  marginTop:52,
  ".tableHead":{
      margin:"0 20px",
      display:"flex",
      lineHeight:"50px",
      borderBottom: "1px solid #737373",
  
      "span":{
          width:"50%",
          paddingLeft:30,
      }
  },
  ".tableBody":{
      height: 306,
      overflowY:"scroll",
      ">div":{
          display:"flex",
          margin:"0 20px",
          wordWrap: "break-word",
          borderTop:" 1px solid #737373",
          ">div":{
              width:"50%",
              padding:10,
              cursor:"pointer"
  
          }
  
      }
  },
  ".tableBody::-webkit-scrollbar": {
      width: 12 /* 鼠标悬停时显示滚动条 */
  },
  
  ".tableBody::-webkit-scrollbar-thumb": {
      backgroundColor: "#737373", /* 滚动条颜色 */
      borderRadius: 8
  }
}))
