import { t } from "i18next"

function App() {
  return (
    <>
      <h2>{t("1. What is Tonkeeper?")}</h2>
      <p>{t("Based on testing and user feedback, Tonkeeper is considered one of the best wallets on the TON blockchain. At the same time, it is also the official wallet of the TON blockchain, integrating various functions to provide a user-friendly operation interface and efficient blockchain management experience.")}</p>
      <h2>{t("2. How to install Tonkeeper wallet?")}</h2>
      <h3>{t("1. Install Google plug-in")}</h3>
      <p>{t("We open Google Chrome and click on the Tonkeeper wallet plug-in installation page:")}:</p>
      <a rel="nofollow" href="https://chromewebstore.google.com/detail/omaabbefbmiijedngplfjmnooppbclkk?utm_source=tonkeeper_index">https://chromewebstore.google.com/detail/omaabbefbmiijedngplfjmnooppbclkk?utm_source=tonkeeper_index</a><span>,{("Click 'Add to Chrome'")}</span>
      <div className="images">
        <img src="/images/add.webp" alt="添加扩展程序" />
      </div>
      <p>{t("A pop-up box will appear, click 'Add Extension'")}</p>
      <div className="images">
        <img src="/images/sure.png" alt="添加扩展程序" />
      </div>
      <p>
        {t("Find the extension in the upper right corner, click the nail icon, and the wallet will be pinned here in the navigation bar.")}
      </p>
      <div className="images">
        <img src="/images/image.png" alt="添加扩展程序" />
      </div>
      <div className="images">
        <img src="/images/image(1).png" alt="添加扩展程序" />
      </div>
      <h3>{t("2. Create a new wallet")}</h3>
      <p>{t("Click on Wallet and then click on the Start button")}</p>
      <div className="images">
        <img src="/images/image(2).png" alt="添加扩展程序" />
      </div>
      <p>{t("Select 'Import new wallet'")}</p>
      <div className="images especial">
        <img src="/images/image.webp" alt="添加扩展程序" />
      </div>
      <p>{t("It will jump to a new page, prompting that the wallet has been created, click 'Continue'")}</p>
      <div className="images">
        <img src="/images/image(3).png" alt="添加扩展程序" />
      </div>
      <p>{t("Copy and save the mnemonic phrase, then click 'Continue'")}</p>
      <div className="images">
        <img src="/images/image(4).png" alt="添加扩展程序" />
      </div>
      <p>{t("Fill in the corresponding mnemonic words and click 'Continue'")}</p>
      <div className="images">
        <img src="/images/image(5).png" alt="添加扩展程序" />
      </div>
      <p>{t("Enter the password, repeat it again, and click 'Continue', so the creation is completed successfully.")}</p>
      <div className="images">
        <img src="/images/image(1).webp" alt="添加扩展程序" />
      </div>
      <h3>{t("3. How to switch testnet")}</h3>
      <p>{t("Follow the sequence marked in the picture below:")}</p>
      <p>{t("(1) Click Wallet, (2) Find Settings in the tab bar and click it, (3) Scroll down to the bottom of the page, find this icon, and click it 5 times quickly.")}</p>
      <div className="images especial">
        <img src="/images/image(6).png" alt="添加扩展程序" />
      </div>
      <p>{t("Click 'Select Network' to switch")}</p>
      <div className="images especial">
        <img src="/images/image(7).png" alt="添加扩展程序" />
      </div>
      <p>{t("If you don’t understand or have any questions, please join the official Telegram group:")}<a href="https://t.me/gtokentool" rel="nofollow">https://t.me/gtokentool</a></p>
    </>
  )
}
export default App