import useJettonStore from "store/jetton-store/useJettonStore";
// import { onchainFormSpec } from "pages/deployer/data";
import { checkImageURL, checkDecimals } from "helpers";
import { Form } from "components/form";
import { JettonStoreState } from "store/jetton-store";
import { jettonDeployController } from "lib/deploy-controller";
import WalletConnection from "services/wallet-connection";
import { Address } from "ton";
import useNotification from "hooks/useNotification";
import { useSetRecoilState } from "recoil";
import { jettonActionsState } from "pages/jetton/actions/jettonActions";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import { useState } from "react";

const inputsName = ["name", "symbol", "decimals", "tokenImage", "description"];

const getInputs = () => {
  const onchainFormSpec = [
    {
      name: "name",
      label: "Jetton Name",
      description: "Your project unabbreviated name with spaces (usually 1-3 words).",
      type: "text",
      default: "Bitcoin Cash",
      required: true,
      errorMessage: "Name required",
    },
    {
      name: "symbol",
      label: "Jetton Symbol",
      description: "Currency symbol appearing in balance (usually 3-5 uppercase chars).",
      type: "text",
      default: "BCH",
      required: true,
      errorMessage: "Symbol required",
    },
    {
      name: "decimals",
      label: "Jetton Decimals",
      description: "The decimal precision of your token (9 is TON default).",
      type: "number",
      validate: checkDecimals,
      default: 9,
      showDefault: true,
      required: true,
      errorMessage: "Decimals amount from 0 to 255 is required", // https://github.com/ton-blockchain/TEPs/blob/master/text/0064-token-data-standard.md#jetton-metadata-attributes
    },
    {
      name: "mintAmount",
      label: "Tokens to Mint",
      description: "Number of initial tokens to mint and send to your wallet address (float).",
      type: "number",
      default: 21000000,
      required: true,
      errorMessage: "Mint amount required",
    },
    {
      name: "description",
      label: "Description",
      description: "Optional sentence explaining about your project.",
      type: "string",
      default: "Low fee peer-to-peer electronic cash alternative to Bitcoin",
    },

    {
      name: "tokenImage",
      label: "Jetton Logo URL",
      description: "URL of 256x256 pixel PNG image of token logo with transparent background.",
      type: "string",
      required: false,
      validate: checkImageURL,
      default: "https://bitcoincash-example.github.io/website/logo.png",
    },
  ];


  return onchainFormSpec
    .filter((specInput) => {
      return inputsName.includes(specInput.name);
    })
    .map((specInput) => {
      return {
        ...specInput,
        disabled: specInput.name === "decimals" ? true : undefined,
      };
    });
};

const createDefaults = (state: JettonStoreState) => {
  const obj = {} as any;
  inputsName.forEach((key: string) => {
    if (key === "tokenImage") {
      obj[key] = state["jettonImage" as keyof JettonStoreState];
    } else {
      obj[key] = state[key as keyof JettonStoreState];
    }
  });
  return obj;
};

const inputs = getInputs();

interface UpdateMetadataProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

function UpdateMetadata({ setOpen }: UpdateMetadataProps) {
  const store = useJettonStore();
  const { isAdmin, getJettonDetails, jettonMaster } = store;
  const [actionInProgress, setActionInProgress] = useState(false);
  const { showNotification } = useNotification();
  const [tonconnect] = useTonConnectUI();
  const walltAddress = useTonAddress();
  if (!isAdmin) {
    return null;
  }

  const onSubmit = async (values: any) => {
    setActionInProgress(true);
    try {
      if (!jettonMaster) {
        throw new Error("");
      }

      await jettonDeployController.updateMetadata(
        Address.parse(jettonMaster),
        {
          symbol: values.symbol,
          name: values.name,
          description: values.description,
          image: values.tokenImage,
          decimals: parseInt(values.decimals).toFixed(0),
        },
        tonconnect,
        walltAddress,
      );
      await getJettonDetails();
      setOpen(false);
    } catch (error) {
      if (error instanceof Error) {
        showNotification(error.message, "error");
      }
    } finally {
      setActionInProgress(false);
      setOpen(false);
    }
  };

  const defaultValues = createDefaults(store);

  return (
    <Form
      submitText="Save"
      disableExample={true}
      onSubmit={onSubmit}
      inputs={inputs}
      isLoading={actionInProgress}
      defaultValues={defaultValues}
      onCancel={() => setOpen(false)}
    />
  );
}

export default UpdateMetadata;
