 import React, { useState } from 'react';
import { Box, Avatar, useMediaQuery } from '@mui/material';
import { styled } from '@mui/system';
import { useJettonLogo } from 'hooks/useJettonLogo';
import coinLogoHover from 'assets/icons/coin-logo-hover.svg';

const Input = styled('input')({
  display: 'none',
});

interface ImageUploadProps {
  onImageChange: (image: File) => void;
}

const ImageUpload: React.FC<ImageUploadProps> = ({ onImageChange }) => {
  const [image, setImage] = useState<string | null>(null);
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];
      onImageChange(file);// 调用父组件传递的回调函数，并传入文件对象 
      const reader = new FileReader();
      reader.onloadend = () => {
        const result = reader.result as string;
        setImage(result);
      };
      onImageChange(file);
      reader.readAsDataURL(file);
    }
  };

  const matches = useMediaQuery('(max-width:599px)');
  const { jettonLogo, setIconHover } = useJettonLogo();

  return (
    <Box sx={{ position: 'relative', width: matches ? 60 : 134,
      height: matches ? 60 : 134,}}>
      <Avatar
        src={image || '/images/uploadImage.png'}
        sx={{ width: '100%', height: '100%', cursor: 'pointer' }}
      />
      <Input accept="image/*" id="upload-image" type="file" onChange={handleImageChange} />
      <label htmlFor="upload-image">
        <img
          alt="Hover icon"
          style={{
            cursor: 'pointer',
            position: 'absolute',
            left: matches ? -1 : 0,
            top: matches ? -1 : 0,
            opacity: jettonLogo.iconHover ? 0.5 : 0,
            zIndex: 1,
            width: matches ? 60 : 134,
            height: matches ? 60 : 134,
          }}
          onMouseEnter={() => setIconHover(true)}
          onMouseLeave={() => setIconHover(false)}
          src={coinLogoHover}
        />
      </label>
    </Box>
  );
};

export default ImageUpload;













