import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// 引入翻译文件
import translations from './locales/translations.json';

// 配置i18n
i18n
  .use(initReactI18next)
  .init({
    resources: {
      en: { translation: Object.fromEntries(Object.entries(translations).map(([key, value]) => [key, key])) },
      zh: { translation: translations }
    },
    lng: 'zh', // 默认语言为英文
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    }
  });

export default i18n;