import { Box, styled, Typography } from "@mui/material";
export { FluidityPage, FluidityTitle };
const FluidityPage = styled(Box)(({ theme }) => ({
  minHeight: "80vh",
  paddingTop: 170,
  [theme.breakpoints.down("md")]: {
    paddingTop: 70,
  },
}));
const FluidityTitle = styled(Box)(({ theme }) => ({
  lineHeight: "60px",
  background: "#15191D",
  width: "100%",
  borderRadius: "10px",
  overflow: "scroll",
  ">div": {
    width: "150%",
    display: "flex",
  },
  "div >div": {
    fontSize: 20,
    color: "#FFF",
    cursor: "pointer",
    padding: "0 20px",
  },
  ".tab.active": {
    background: " #192C47",
    borderRadius: "6px",
  },
  [theme.breakpoints.down("md")]: {
    width: "100%",
    "div >div": {
      fontSize: 16,
    },
  },
}));
