import { Api, HttpClient } from "tonapi-sdk-js";
import { message } from 'antd';
import copy from 'copy-to-clipboard'
const httpClient = new HttpClient({
  baseUrl: "https://tonapi.io",
  baseApiParams: {
    headers: {
      Authorization: `bearer ${"AH5I4JOQ4MJKTJQAAAAFGGK3GGVBH2LGTKW5UKR2GGZI36JMO7XQDMQ6EXHZTM433N6IINA"}`,
      "Content-type": "application/json",
    },
  },
});
export const client = new Api(httpClient);
export function SliceAddress(address) {
  let header = address.slice(0, 4);
  let fooler = address.slice(-4);
  return header + "..." + fooler;
}

const copyHandler = (content) => {
  copy(content)
  message.success('复制成功');
}

export default copyHandler
