import { Box, useMediaQuery } from "@mui/material";
import { SearchBar } from "components/header/headerSearchBar";
import { useNetwork } from "lib/hooks/useNetwork";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MarketValuePage } from "./style";

interface TokenInfo {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  totalSupply: string;
}

interface AccountInfo {
  tokens?: TokenInfo[];
}



function MarketValue() {

  // const matches = useMediaQuery("(min-width:900px)");
  const { network } = useNetwork();
  const navigate = useNavigate()

  return (
    <MarketValuePage>
      市值
    </MarketValuePage>
  );
}

export default MarketValue;
