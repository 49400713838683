import { Chip, Typography, useMediaQuery } from "@mui/material";
import { APP_DISPLAY_NAME, ROUTES } from "consts";
import { LogoWrapper, ImageWrapper } from "./styled";
import { useNetwork } from "lib/hooks/useNetwork";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";

export const AppLogo = () => {
  const matches = useMediaQuery("(min-width:900px)");
  const navigate = useNavigatePreserveQuery();
  const { network } = useNetwork();
  return (
    <LogoWrapper onClick={() => navigate(ROUTES.deployer)}>
      <ImageWrapper>
        <img src='/images/logo.png' alt="Logo" />
      </ImageWrapper>
      {matches && (<Typography variant="h4" mr={2} ml={1}>{APP_DISPLAY_NAME}</Typography>)}

      {network === "testnet" && <Chip sx={{ ml: 1 }} label="testnet" />}
    </LogoWrapper>
  );
};
