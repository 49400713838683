import useNotification from "hooks/useNotification";
import { useForm } from "react-hook-form";
import { Box, Tooltip, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { AppButton } from "components/appButton";
import { CenteringWrapper } from "components/header/headerSearchBar/styled";
import { NFTStorage } from "nft.storage";
import axios from 'axios';

import {
  JettonFormTitle,
  LogoLink,
  LogoLinkButton,
  StyledActionBtn,
  StyledForm,
  StyledFormInputs,
  logoInput
} from "components/form/styled";
import { useTranslation } from 'react-i18next';
import { Input } from "components/form/input";
import { useJettonLogo } from "hooks/useJettonLogo";
import { AppHeading } from "components/appHeading";
import { useTonAddress } from "@tonconnect/ui-react";
import { onConnect } from "utils";
import ImageUpload from "components/ImageUpload/ImageUpload";
import { message } from "antd";

interface FormProps {
  onSubmit: (values: any) => Promise<void>;
  inputs: any[];
  disableExample?: boolean;
  submitText: string;
  defaultValues?: {};
  onCancel?: () => void;
  isLoading?: boolean;
}

export function Form({
  onSubmit,
  inputs,
  disableExample,
  submitText,
  defaultValues,
  onCancel,
  isLoading,
}: FormProps) {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const address = useTonAddress();
  const { jettonLogo, setIconHover } = useJettonLogo();
  const [logoAlertPopup, setLogoAlertPopup] = useState(false);
  const matches = useMediaQuery("(max-width:599px)");
  const matches1 = useMediaQuery("(max-width:900px)");
  const { control, handleSubmit, formState, setValue, clearErrors, watch, getValues } = useForm({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues,
  });
  const errors = formState.errors as any;
  const onFormError = (value: any) => {
    const firstError = value[Object.keys(value)[0]];
    if (!firstError) {
      return;
    }
    showNotification(<>{firstError.message}</>, "warning", undefined, 3000);
  };

  const onExampleClick = useCallback((name: never, value: never) => {
    setValue(name, value);
  }, []);


  const jettonData: any = watch();

  // console.log(jettonLogo.logoUrl, 'jettonLogo.logoUrl')
  const [Logo, setLogo] = useState<string>();
  const [logoLinkState, setLogoLinkState] = useState<boolean>(false);

  useEffect(() => {
    //@ts-ignore
    setValue("tokenImage", Logo);
  }, [Logo]);
  const handleImage = async (image: File) => {
    console.log(image, 'images')
    let uri;
    try {
      //生成图片url
      if (!image) {
        console.log("return")
        return;
      }

      const url = 'https://up.gtokentool.com/File/';

      const forms = new FormData();
      forms.append("name", image.name);
      // @ts-ignore
      forms.append("file", image);
      const response = await axios.post(url, forms);
      console.log(response, 'response');
      uri = response.data
      console.log(uri, "_imageUri");
      setLogo(uri);
    } catch (error) {
      console.log(error, 'error')
    }
  }
  return (
    <StyledForm
      onSubmit={handleSubmit(() => {
        if (!Logo || jettonLogo.hasError) {
          message.error(t('Please upload a logo or the logo is being uploaded, please wait.'))
          setLogoAlertPopup(true);
          return;
        }
        // console.log(jettonLogo.hasError,'jettonLogo.hasError')

        onSubmit(getValues());
      }, onFormError)}>
        <h1 style={{display:"none"}}>ton创建代币</h1>
        <h2 style={{display:"none"}}>ton创建代币</h2>
        <h3 style={{display:"none"}}>ton创建代币</h3>
      <Box sx={{ display: "flex" }} mb={matches ? 4 : 10}>
        <ImageUpload onImageChange={handleImage}></ImageUpload>

        <Box ml={!matches ? 4 : 2}>
          <JettonFormTitle>
            {t("Upload Logo")}
          </JettonFormTitle>

          <Tooltip
            arrow
            title={
              jettonData.description && jettonData.description?.length > 80
                ? jettonData.description
                : ""
            }>

            <Box sx={{ maxWidth: 300, maxHeight: 60, marginTop: 2 }}>
              <AppHeading
                text={t("You need at least 5 ton in your wallet to complete the deployment")}
                limitText={80}
                variant="h4"
                fontWeight={500}
                fontSize={matches ? 12 : 14}
                color="rgb(118, 137, 174)"
              />

            </Box>

          </Tooltip>
          <Box sx={{ maxWidth: 300, maxHeight: 60, marginTop: 2 }}>
            <AppHeading
              text={t("The maximum image size cannot exceed 1M")}
              limitText={80}
              variant="h4"
              fontWeight={500}
              fontSize={matches ? 12 : 14}
              color="rgb(118, 137, 174)"
            />
          </Box>

          <div onClick={() => setLogoLinkState(true)}>
            <Box sx={{ maxWidth: 300, maxHeight: 60, marginTop: 2, cursor: "pointer", textDecoration: "underline",textDecorationColor:"rgb(118, 137, 174)" }}>
              <AppHeading
                text={t("If you cannot upload, please click here")}
                limitText={80}
                variant="h4"
                fontWeight={500}
                fontSize={matches ? 12 : 14}
                color="rgb(118, 137, 174)"
              />
            </Box>
          </div>
        </Box>
      </Box>
      {logoLinkState && (
        <>
          <Box mt={6} mb={2}>
            <LogoLink>
              <input type="text" value={Logo} onChange={(e) => setLogo(e.target.value)} placeholder="输入图片链接" />
              <LogoLinkButton><div onClick={() => window.location.href = ('https://www.gtokentool.com/uploadImage')}>
                去上传</div></LogoLinkButton>
            </LogoLink>
          </Box>
          <Box ml={3} mb={3} sx={{ maxWidth: 300, maxHeight: 60 }}>
            <AppHeading
              text={t("Supported image types: png, jpg, gif")}
              limitText={80}
              variant="h4"
              fontWeight={500}
              fontSize={matches ? 16 : 20}
              color="rgb(118, 137, 174)"
            />
          </Box></>
      )}

      <StyledFormInputs>
        {inputs
          .filter((i) => i.name !== "tokenImage")
          .filter((i) => !i.disabled)
          .map((spec: any, index: number) => {
            return (
              <Input
                disableExample={disableExample}
                required={spec.required}
                description={spec.description}
                clearErrors={clearErrors}
                key={index}
                error={errors[spec.name]}
                name={spec.name}
                type={spec.type}
                control={control}
                label={spec.label}
                defaultValue={spec.default || ""}
                onExampleClick={() => onExampleClick(spec.name as never, spec.default as never)}
                disabled={spec.disabled}
                errorMessage={spec.errorMessage}
                validate={spec.validate}
                showDefault={spec.showDefault}
              />
            );
          })}
      </StyledFormInputs>
      <StyledActionBtn>
        {!address ? (
          <AppButton
            height={matches ? 38 : 44}
            width={150}
            fontWeight={700}
            type="button"
            onClick={onConnect}
          >
            {t("Connect wallet")}
          </AppButton>
        ) : (
          <CenteringWrapper sx={{ justifyContent: "center" }}>
            {onCancel && (
              <Box sx={{ width: 96, height: 44 }}>
                <AppButton
                  disabled={jettonLogo.isLoading}
                  transparent
                  onClick={onCancel}
                  type="button">
                  {t("Cancel")}
                </AppButton>
              </Box>
            )}
            <Box sx={{ width: "100%", height: 44 }}>
              <AppButton disabled={jettonLogo.isLoading} type="submit" loading={isLoading}>
                {submitText}
              </AppButton>
            </Box>
          </CenteringWrapper>
        )}
      </StyledActionBtn>
    </StyledForm>

  );
}
