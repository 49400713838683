import React from 'react'
import { TonClient, toNano, } from '@ton/ton'
import { DEX, pTON } from '@ston-fi/sdk'
import { Button } from 'antd'
import { getClient } from "lib/get-ton-client";
import { getHttpEndpoint } from "@orbs-network/ton-access";
import { useTonAddress, useTonConnectUI, SendTransactionRequest } from "@tonconnect/ui-react";
import { Address } from 'ton'

const endpointP = getHttpEndpoint({
  network: "mainnet",
});

function Bot() {
  const address = useTonAddress();
  console.log("11111111111111")
  const addPool = async () => {
    // try {
    //   const USER_WALLET_ADDRESS = address; // ! replace with your address
    //   const JETTON_0_ADDRESS = "EQD2Y_34EklICZXFNTFefVjQXG5Dgaq2b-UN5T38eGMudL83"; // STON

    //   const client = new TonClient({
    //     endpoint: "https://toncenter.com/api/v2/jsonRPC",
    //   });

    //   const router = client.open(new DEX.v1.Router());

    //   const txsParams = await Promise.all([
    //     // deposit 1 TON to the STON/TON pool and get at least 1 nano LP token
    //     router.getProvideLiquidityTonTxParams({
    //       userWalletAddress: USER_WALLET_ADDRESS,
    //       proxyTon: new pTON.v1(),
    //       sendAmount: toNano("1"),
    //       otherTokenAddress: JETTON_0_ADDRESS,
    //       minLpOut: "1",
    //       queryId: 12345,
    //     }),
    //     // deposit 0.5 STON to the STON/TON pool and get at least 1 nano LP token
    //     router.getProvideLiquidityJettonTxParams({
    //       userWalletAddress: USER_WALLET_ADDRESS,
    //       sendTokenAddress: JETTON_0_ADDRESS,
    //       sendAmount: toNano("0.5"),
    //       otherTokenAddress: new pTON.v1().address,
    //       minLpOut: "1",
    //       queryId: 123456,
    //     }),
    //   ]);
    // } catch (error) {
    //   console.log(error, 'error')
    // }
  }


  const exchange = async () => {
    // try {
    //   console.log(await endpointP, 'await endpointP')
    //   const client = new TonClient({
    //     endpoint: "https://toncenter.com/api/v2/jsonRPC",
    //   });

    //   const router = client.open(new DEX.v1.Router());
    //   console.log(address, 'address')
    //   console.log(router, 'router')
    //   console.log(router.address.toString())

    //   const pool = await router.getPoolAddress({
    //     token0: "EQDsRjMxOJjEBPsnGJH-DBz5qoLKYw7KgHtbqZdKhgUyUGUi",
    //     token1: "EQDpCbyqoD55euFbwngXA6fpeNu3zGy4jSMqHzXTMSxuyc2F",
    //   })
    //   console.log(pool.toString(), 'pool')
    //   return
    //   // swap 1 TON to STON but not less than 1 nano STON
    //   const txParams = await router.getSwapTonToJettonTxParams({
    //     userWalletAddress: address, // ! replace with your address
    //     proxyTon: new pTON.v1(),
    //     offerAmount: toNano("1"),
    //     askJettonAddress: "EQA2kCVNwVsil2EM2mB0SkXytxCqQjS4mttjDpnXmwG9T6bO", // STON
    //     minAskAmount: "0.001",
    //     queryId: 12345,
    //   });


    // } catch (error) {
    //   console.log(error)
    // }
  }

  const v2 = async () => {
    // try {

    //   const client = new TonClient({
    //     endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
    //   });

    //   const router = client.open(
    //     DEX.v2.Router.create(
    //       "kQCas2p939ESyXM_BzFJzcIe3GD5S0tbjJDj6EBVn-SPsEkN" // CPI Router v2.0.0
    //     )
    //   );

    //   const proxyTon = pTON.v2.create(
    //     "kQDwpyxrmYQlGDViPk-oqP4XK6J11I-bx7fJAlQCWmJB4m74" // pTON v2.0.0
    //   );

    //   // swap 1 TON to TestRED but not less than 1 nano TestRED
    //   const txParams = await router.getSwapTonToJettonTxParams({
    //     userWalletAddress: "", // ! replace with your address
    //     proxyTon: proxyTon,
    //     offerAmount: toNano("1"),
    //     askJettonAddress: "kQDLvsZol3juZyOAVG8tWsJntOxeEZWEaWCbbSjYakQpuYN5", // TestRED
    //     minAskAmount: "1",
    //     queryId: 12345,
    //   });
    // } catch (error) {
    //   console.log(error)
    // }
  }
  return (
    <div style={{marginTop:150,minHeight:"60vh"}}>
      <Button onClick={addPool}>点击</Button>
      <Button onClick={exchange}>exchange</Button>
      <Button onClick={v2}>v2</Button>
    </div>
  )
}

export default Bot