import { useTranslation } from "react-i18next";
import { Content, CoverLay, LearnPage, List, Nav, Title } from "./style"
import { useRef, useState } from "react";

function App() {
  const [Tutorial, setTutorial] = useState(false);
  const { t } = useTranslation();
  const [id, setId] = useState(1)

  const options = [
    { id: 1, text: t('Tonkeeper wallet installation tutorial') },
    { id: 2, text: t('Tutorial on receiving test coins from TON') },
    { id: 3, text: t('Ton chain one-click currency issuance tutorial') },
    { id: 4, text: t('Tutorial on sending tokens in batches') },
    { id: 5, text: t('STON.fi Add Liquidity Tutorial') }
  ];
  const title = useRef(t('Tonkeeper wallet installation tutorial'));
  const handleTitleChange = (newTitle: string, id: number) => {
    console.log(newTitle, 'newTitle')
    title.current = newTitle
    setTutorial(!Tutorial)
    setId(id)
  };
  return (
    <>
      <LearnPage>
        <List>
          <h1>TON学习</h1>
        </List>
        <List onClick={() => setTutorial(!Tutorial)}>
          <h1>{options[id - 1].text}</h1>
          <div className="Img">
            {!Tutorial && (
              <img src="/images/Tutorial.webp" alt="Tutorial" />
            )}
            {Tutorial && (
              <img src="/images/Tutorial(1).webp" alt="Tutorial" />
            )}

          </div>

        </List>
        <Content>
        {/* {Tutorial && (
          <Nav>
            <div className="list">
              {options.map((option) => (
                <div key={option.id} onClick={() => handleTitleChange(option.text, option.id)}>
                  {t(option.text)}
                </div>
              ))}
            </div>
          </Nav>
        )}
        <CoverLay style={{ opacity: Tutorial ? 0.5 : 1 }}></CoverLay>
          <h2>TON是什么？</h2>
          <p>TON全称是The Open Network，最初是Telegram 的创始人创建的区块链项目，因为一些纠纷，最后由开源社区和开发者接管了这个项目。
          </p>
          <p>
            TON目前市值排名第9， 估值在167亿美金。它最早是依靠 Telegram 的小程序和小游戏快速获得关注，但是它的核心竞争力是非常优异的性能。测试数据显示，Toncoin 的 TPS 指标已经超过了 Solana，性能表现非常出色。此外，TON它是背靠Telegram平台的，拥有来自 9 亿用户的流量入口。在近期，TON 网络显示出前所未有的增长势头，日活跃地址、交易量、以及总锁仓价值（TVL）连续创新高，链上的USDT供应量也突破5亿枚。
          </p>
          <p>
            本质上讲，TON网络就是内置在Telegram软件里边的一个高性能公链。它使用的是POS的机制，跟以太坊啊跟solana是一样的，ton还会采用分片的机制，也就是以太坊未来会采用的机制，而且ton网络的智能合约还是异步智能合约。简单来说，就是ton网络的处理速度、性能会比较好，完全是能够跟Solana相媲美，甚至比Solana更好，不过他们各有优缺点而已。
          </p>
          <h2>从用户角度看TON</h2>
          <p>TON大幅降低新用户进入币圈的门槛，并且使用体验极其流畅
          </p>
          <p><b>第一点</b> 是Telegram自带了一个TON钱包，这个钱包的使用非常顺畅，就像微信里的微信钱包一样。
          </p>
          <p>第二点是Telegram官方推出了一个OTC市场。用户可以在支持的地区内，方便地使用法币买卖加密货币，不论他们来自哪个国家。
          </p>
          <p>第三点是Telegram好友之间可以随意转账，这种转账不收取任何费用，类似于微信的转账和红包功能。不过，这些转账不会记录在链上。
          </p>
          <p>第四点是Telegram生态中的所有产品将逐步接入加密货币支付功能。
          </p>
          <p>
            第五点是用户在使用Telegram上的加密货币产品时，无需支付gas费，这部分费用由开发团队承担。

          </p>
          <p>综上所述，这五个方面极大地降低了新用户进入币圈的门槛，同时提供了非常流畅的使用体验。
          </p>
          <h2>TON和Telegram的愿景</h2>
          <p>TON和Telegram的目标是创建一个全球化的“微信生态”，通过整合加密货币和稳定币支付，来实现全球范围内的金融支付系统。
          </p>
          <h3>1、全球“微信生态”：</h3>
          <p>TON和Telegram希望打造一个类似于微信的综合生态系统，涵盖消息、社交、支付和各种日常服务。用户可以在这个平台上完成从聊天到购物等多种活动，无需离开应用。
          </p>
          <h3>2、加密货币和稳定币支付：</h3>
          <p>计划通过加密货币（如TON的原生代币）和稳定币来实现这一目标。这将使跨境支付更加便捷、高效，并减少传统金融系统中的中间环节和费用。
          </p>
          <h3>3、全球金融支付系统：</h3>
          <p>最终目的是创建一个去中心化的全球金融支付网络，使用户能够在世界任何地方进行快速、安全的支付和转账。通过利用区块链技术，这个系统可以确保交易的透明度和安全性，同时降低成本。</p>
          <h2>TON的生态和用途？</h2>
          <p>TON的生态和用途非常广泛，目前，TON生态系统涵盖多个重要组成部分：钱包、支付、去中心化存储和域名。
          </p>
          <h3>1. 钱包</h3>
          <p>TON生态系统中有官方钱包，用户可以在钱包中进行各种操作，如转账和支付。官方版本只是其中之一，其他开发者也可以创建自己的钱包应用。</p>
          <h3>2. 支付</h3>
          <p>跨境转账和好友间转账
          </p>
          <p>使用TON购买全球匿名ECM手机卡</p>
          <p>
            基于Telegram的DNS地址购买（域名）
          </p>
          <p>
            在Telegram中进行打赏和捐赠
          </p>
          <p>
            购买Telegram会员服务

          </p>
          <p>
            TON的支付系统类似于微信和Line，但其支付基于区块链网络，使得交易更加安全和透明。

          </p>
          <h3>3. 去中心化存储</h3>
          <p>TON网络提供去中心化存储，确保信息安全，避免传统集中存储的风险。
          </p>
          <h3>4. TON域名</h3>
          <p>TON生态系统还包括域名服务，用户可以购买和使用基于TON网络的域名。
          </p>
          <h3>5. 生态系统的其他部分</h3>
          <p>TON生态系统欢迎众多开发者参与，许多优秀的开发者正在为其贡献力量。例如，用户可以在Telegram中进行交易，有许多交易机器人支持这些操作。通过Telegram钱包，用户还可以进行质押。
          </p>
          <p>此外，还有一些基于TON网络的热门应用，如NotCoin和Hamster Kombat等小游戏，以及其他社交产品。这些应用都展示了TON网络的潜力和活力。
          </p>
          <h3>总结</h3>
          <p>TON生态系统不仅涵盖钱包、支付、去中心化存储和域名，还吸引了大量开发者共同建设。它正在形成一个繁荣且快速发展的生态系统，展现出巨大的潜力和前景。
          </p>
          <h2>支持TON的中心化交易所和稳定币发行商</h2>
          <h3>中心化交易所
          </h3>
          <p>以下几家全球排名前几的加密货币交易所，都对TON网络和Telegram生态表示了强力支持：
          </p>
          <p>bybit</p>
          <p>欧易okx</p>
          <p>抹茶mexc</p>
          <p>bitget</p>
          <h3>稳定币发行商</h3>
          <p>Tether和TON网络达成了官方合作，支持USDT在TON网络上的发行和使用。</p>
          <h4>全球支付系统</h4>
          <p>TON和Telegram的愿景是创建一个全球性的支付系统。然而，当前每个国家的法律和货币体系各不相同，难以实现统一的支付方式。因此，他们希望通过区块链技术来克服这些障碍。
          </p>
          <h4>关键角色USDT</h4>
          <p>在区块链领域，USDT（Tether发行的稳定币）是发行量最大、使用率最高、最容易被理解和接受的加密货币之一。TON网络上已经发行了4.5亿美元的USDT，这在短时间内是一个显著的成就。
          </p>
          <h4>TON网络的优势</h4>
          <p>4.5亿美元的USDT发行量相当于BSC（币安智能链）网络上的USDT发行量的一半，展示了TON网络的强劲增长势头。TON网络上的金钱流转速度也非常快，进一步证明了其作为支付网络的潜力。
          </p>
          <h4>总结</h4>
          <p>TON和Telegram的目标是通过区块链技术创建一个全球性的支付系统，利用USDT这样被广泛接受的稳定币来解决跨境支付问题。TON网络在短时间内取得的成就和快速的资金流动，显示了其在实现这一目标上的巨大潜力。</p>
          <h2>TON 网络的现状</h2>
          <h3>TON网络上面USDT的数据</h3>
          <p>通过比较 USDC 在各大区块链上的发行量，我们可以更好地理解 TON 网络上 USDT 的数据。
          </p>
          <p>在美国，Coinbase 是合规地区的龙头交易所，因为它已经在纳斯达克上市，并且拥有自己的 Layer 2 网络——Base 链。同时，Coinbase 还发行了自己的稳定币 USDC。USDC 在 Base 链上的支持力度非常大。在 USDC 的官网上，我们可以看到 USDC 在不同区块链网络上的发行量。
          </p>
          <p>例如，USDC 在 Arbitrum 上的发行量为 11.6 亿美元，在 Base 链上的发行量为 30 亿美元，而在以太坊上发行量最大，达到 245 亿美元。在 Solana 上，USDC 的发行量为 20 亿美元。
          </p>
          <p>通过这些数据，我们可以看到 TON 网络在短时间内就达到了 4.5 亿美元的 USDT 发行量，这展示了其快速增长和高效的发展速度。相比之下，其他网络花费了更长的时间才达到目前的发行量。
          </p>
          <h3>TON网络的数据</h3>
          <div className="images">
            <img src="/images/learn.png" alt="添加扩展程序" />
          </div>
          <p>
            TON 网络的发行量约为 51 亿。在 TON 网络上，每天的手续费大约为 13,580 美元，每天销毁的 TON 数量为 6,790，而每天新发行的 TON 数量为 54,763。由于 PoS 网络的出块奖励机制，每天销毁的量大约占新发行量的 1/9。

          </p>
          <p>TON 网络目前有大约 880 万个钱包地址，每天新增的钱包数量约为 71,000，日活跃钱包数量约为 40 万，月活跃钱包数量约为 520 万。
          </p>
          <p>在 DeFi 方面，TON 网络上最大的两个去中心化交易所（DEX）锁定的资金总额不到 1 亿美元，每天的交易量大约为 1,000 万美元。此外，TON 代币在流动性池中的质押数量以及质押的协议分布也有详细数据。
          </p>
          <p>TON 网络的独立节点数量为 347，这些节点质押了大约 5.2 亿个 TON 代币，分布在 30 个国家。
          </p>
          <p>通过监控这些数据，我们可以更好地了解 TON 网络的发展状况。数据网站提供了非常详细的信息，大家可以直接访问相关网址：<a href="https://tonstat.com/">https://tonstat.com/</a> ，实时监控和了解 TON 网络的各项数据变化。这对于我们监控和判断 TON 网络的发展非常重要。
          </p>
          <h3>TON网络路线图</h3>
          <div className="images">
            <img src="/images/learn1.png" alt="添加扩展程序" />
          </div>
          <p>TON区块链的路线图可以分为四个主要板块：
          </p>
          <p>1.TON区块链的优势：这是关于TON区块链目前正在进行的工作以及它在区块链技术中的优势。
          </p>
          <p>2.TON代理：关于TON代理的详细信息，包括它的功能和应用。
          </p>
          <p>3.TON支付：这是关于TON支付系统的情况，如何使用TON进行支付以及相关的发展。</p>
          <p>4.TON存储：这是关于TON存储的情况，如何在TON网络上进行数据存储及其优势。</p>
          <p>这四个部分是TON官方非常重视的四个大基建。官方不仅自己进行开发，还欢迎其他团队参与。在路线图中，可以清楚地看到TON每个季度计划完成的任务以及这些任务的完成情况。并且也能够看出，TON网络的开发进度和开发能力非常强大且快速。
          </p>
          <p>接下来，TON将重点开发稳定币工具包，以更好地支持支付系统的发展，还包括跨链桥、排序器、分片等多种新开发项目。如果对TON感兴趣，可以关注他们接下来的计划和完成情况。</p>
          <p>TON路线图地址： <a href="https://ton.org/zh/roadmap">https://ton.org/zh/roadmap</a></p> */}
        </Content>
      </LearnPage>
    </>
  )
}
export default App