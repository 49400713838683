import { styled } from "@mui/material";
import { Box } from "@mui/system";
import { createContext, useEffect } from "react";
import { APP_GRID, ROUTES } from "consts";
import { Navigate, Outlet, Route, Routes, useLocation } from "react-router-dom";
import { DeployerPage, Jetton } from "pages";
import SenderToken from 'pages/SenderToken'
import analytics from "services/analytics";
import { Footer } from "components/footer";
import { Header } from "components/header";
import { useJettonLogo } from "hooks/useJettonLogo";
import useNotification from "hooks/useNotification";
import Management from "pages/Management";
import MarketValue from "pages/MarketValue/MarketValue";
import Bot from 'pages/bot'
import Fluidity from "pages/Fluidity";
import Create from "pages/Fluidity/Create";
import Remove from "pages/Fluidity/Remove";
import Burn from "pages/Fluidity/Burn";
import Docs from "pages/Docs";
import Ques from "pages/Docs/Ques";
import Doc from "pages/Docs/Doc";
import Learn from "pages/Learn";

analytics.init();

const AppWrapper = styled(Box)(() => ({
  // height:"230vh",
  background: " url(/images/bg.png) no-repeat center center ",
  backgroundSize: "cover",
  display: "flex",
  flexDirection: "column",
}));

const FooterBox = styled(Box)(() => ({
  display: "flex",
  flex: 1,
  alignItems: "flex-end",
  justifyContent: "center",
}));

const ScreensWrapper = styled(Box)({
  "*::-webkit-scrollbar": {
    display: "none",
  },
  "*::-webkit-scrollbar-track": {
    display: "none",
  },
  "*::-webkit-scrollbar-thumb": {
    display: "none",
  },
});

const FlexibleBox = styled(Box)(({ theme }) => ({
  width: "60%",
  // width: "calc(100% - 50px)",
  marginLeft: "auto",
  marginRight: "auto",

  [theme.breakpoints.down("sm")]: {
    width: "100%",
  },
}));

export const EnvContext = createContext({
  isSandbox: false,
  isTestnet: false,
});

const PageNotFound = () => {
  const { showNotification } = useNotification();

  useEffect(() => {
    showNotification("Page not found", "error");
  }, []);

  return <Box />;
};

interface ContentWrapperProps {
  children?: any;
}

const ContentWrapper = ({ children }: ContentWrapperProps) => {
  return (
    <FlexibleBox>
      {children}
      <Outlet />
    </FlexibleBox>
  );
};

const App = () => {
  const { resetJetton } = useJettonLogo();
  const location = useLocation();

  useEffect(() => {
    resetJetton();
  }, [location.pathname]);

  return (
    <AppWrapper>
      <EnvContext.Provider
        value={{
          isSandbox: window.location.search.includes("sandbox"),
          isTestnet: window.location.search.includes("testnet"),
        }}>
        <ScreensWrapper>
          <Routes>
            <Route
              path="*"
              element={
                <>
                  <Header />
                  <Navigate to="/" />
                  <PageNotFound />
                </>
              }
            />
            <Route path="/" element={<Header />}>
              <Route path="/" element={<ContentWrapper />}>
                <Route path={ROUTES.deployer} element={<DeployerPage />} />

                <Route path={ROUTES.jettonId} element={<Jetton />} />

                <Route path="/send" element={<SenderToken />} />
                <Route path="/management" element={<Management />} />

                <Route path="/marketValue" element={<MarketValue />} />
                <Route path="/fluidity" element={<Fluidity />}>
                  <Route index element={<Create />} />
                  <Route path="create" element={<Create />} />
                  <Route path="remove" element={<Remove />} />
                  <Route path="burn" element={<Burn />} />
                </Route>
                <Route path="/bot" element={<Bot />} />
                <Route path="/docs" element={<Docs />}>
                  <Route index element={<Doc />} />
                  <Route path="doc" element={<Doc />} />
                  <Route path="question" element={<Ques />} />
                </Route>
                <Route path="/learn" element={<Learn />}/>

              </Route>
            </Route>
          </Routes>
        </ScreensWrapper>
      </EnvContext.Provider>
      <FooterBox mt={5}>
        <Footer />
      </FooterBox>
    </AppWrapper>
  );
};

export default App;
