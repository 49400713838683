import { AppBar, styled, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { APP_GRID } from "consts";

const HeaderWrapper = styled(AppBar)(({ theme }) => ({
  background: "#000507",
  height: 132,
  zIndex: 99,
  position: "fixed",
  left: 0,
  [theme.breakpoints.down("lg")]: {
    // paddingTop: 10,
    height: 80,
    borderBottom: "1px solid #999"

  },
  [theme.breakpoints.down("sm")]: {
    // paddingTop: 10,
    height: 50,
    borderBottom: "1px solid #999"

  },
}));
const List = styled(AppBar)(({ theme }) => ({
  width: "100vw",
  position: "absolute",
  right: " 0",
  top: "80px",
  zIndex: "12",
  backgroundColor: "rgba(26, 26, 26, 0.5)",
  borderRadius: "5px",
  border: "1px solid #242424",
  [theme.breakpoints.down("sm")]: {
  top: "50px",

  }
}))
const HeaderContent = styled(Box)(({ theme }) => ({
  width: "60%",
  margin: "0 auto",
  height: 80,
  [theme.breakpoints.down("lg")]: {
    width: "calc(100% - 20px)",
  },
  [theme.breakpoints.down("md")]: {
    display: "flex",
    alignItems: "center",

  },
}));
const Nav = styled(Box)(({ theme }) => ({
  display: "flex",
  color: "#FFF",
  lineHeight: "50px",
  justifyContent: "center",
  fontSize: 20,
  borderBottom:"1px solid #4d4d4d",
  borderTop:"1px solid #4d4d4d",
  [theme.breakpoints.down("lg")]: {
    display: "none",
  },
  "div:hover": {
    cursor: "pointer",
    color: "#0DACFE"
  },
  "div": {
    marginRight:"15px"
  },
}));

const HeaderOptionalContent = styled(Box)(({ theme }) => ({
  // flex: 0,
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  height: "100%",
  // [theme.breakpoints.down("md")]: {
  //   justifyContent: "flex-start",
  //   height: "initial",
  // },
}));

const HeaderExampleTextWrapper = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  [theme.breakpoints.down("md")]: {
    display: "none",
  },
}));

const HeaderExampleText = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  opacity: 0.3,
  color: "black",
  display: "inline",
  padding: 0,
  margin: 0,
  marginLeft: theme.spacing(4),
}));

const HeaderExampleLink = styled(Typography)(() => ({
  color: "black",
  display: "inline",
  fontWeight: 800,
  "&:hover": {
    cursor: "pointer",
  },
}));

export {
  HeaderWrapper,
  HeaderContent,
  HeaderOptionalContent,
  HeaderExampleTextWrapper,
  HeaderExampleText,
  HeaderExampleLink,
  Nav,
  List,
}
