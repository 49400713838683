import { Box, useMediaQuery } from "@mui/material";
import { SearchBar } from "components/header/headerSearchBar";
import { useCallback, useEffect, useState } from "react";
import copyHandler, { client, SliceAddress } from '../../config'
import { ManagementList, PaginationSwapper } from "./style";
import { Address } from "@ton/core";
import { CopyOutlined } from "@ant-design/icons";
import { ROUTES } from "consts";
import { useNavigate } from "react-router-dom";
import { useTonAddress } from "@tonconnect/ui-react";
import { Pagination } from "antd";
import type { PaginationProps } from "antd";
interface TokenInfo {
  name: string;
  address: string;
  symbol: string;
  decimals: number;
  totalSupply: string;
}

interface AccountInfo {
  tokens?: TokenInfo[];
}

interface Jetton {
  balance: string;
  jetton: {
    address: string;
    decimals: number;
    image: string;
    name: string;
    symbol: string;
    verification: string;
  };
  wallet_address: {
    address: string;
    is_scam: boolean;
    is_wallet: boolean;
  };
}

function Management() {
  const walletAddress = useTonAddress();
  console.log(walletAddress,'walletAddress')

  const matches = useMediaQuery("(max-width:599px)");

  const [example, setExample] = useState<string | undefined>(undefined);
  const resetExample = useCallback(() => {
    setExample(undefined);
  }, []);


  const [mobileMenu, setMobileMenu] = useState(true);
  const [jettons, setJettons] = useState<Jetton[]>([])
  const [currentJettons, setCurrentJettons] = useState<Jetton[]>([])


  const getJettons = async () => {
    const jettons = await client.accounts.getAccountJettonsBalances(walletAddress)
    // console.log(jettons, 'jettons')
    setJettons(jettons.balances)
    setCurrentJettons(jettons.balances.slice(0, 10))

  }
  console.log(currentJettons, 'currentJettons')
  // console.log(currentJettons, 'currentJettons')
  useEffect(() => {
    getJettons()
  }, []);



  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  // 页码改变时的处理函数
  const handlePageChange = (page: number) => {
    console.log(page, 'page')
    setCurrentPage(page);
    setCurrentJettons(jettons.slice(10 * (page - 1), 10 * (page - 1) + 10))
  };
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <div className="icon">
          <img src="/images/left.png" alt="left" style={{ opacity: "0.5" }} />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div className="icon">
          <img src="/images/righticon.png" alt="righticon" />
        </div>
      );
    }
    return originalElement;
  };

  return (
    <div style={{ minHeight: "60vh", marginTop: "100px" }}>

      <Box flex={1}>
        <SearchBar
          example={example}
          resetExample={resetExample}
          closeMenu={() => setMobileMenu(false)}
        />
      </Box>
      <ManagementList>
        {/* {!matches && ( */}
        <>
          <div className="title">
            <span>地址</span>
            <span>简称</span>
            <span>精度</span>
            <span>余额</span>
          </div>
          {jettons.length > 0 && (
            <>
              {currentJettons.map((jetton, index) => (
                <div className="list" onClick={() => navigate(`${ROUTES.jetton}/${Address.parse(jetton.jetton.address).toString()}`)} key={index}>

                  <span>{SliceAddress(Address.parse(jetton.jetton.address).toString())}<CopyOutlined onClick={() => copyHandler(Address.parse(jetton.jetton.address).toString())}></CopyOutlined>
                  </span>
                  <span>{jetton.jetton.symbol}</span>
                  <span>{jetton.jetton.decimals}</span>
                  <span>{Number(jetton.balance) / 10 ** (jetton.jetton.decimals)}</span>

                </div>
              ))}
              <PaginationSwapper>
                <Pagination
                  current={currentPage}
                  onChange={handlePageChange}
                  itemRender={itemRender}
                  total={jettons.length}
                  pageSize={10} />
              </PaginationSwapper>
            </>
          )}
          {(jettons.length === 0) && (
            <div className="noData">
              <div>
                <img src="/images/no_data.png" alt="no_data" />
                <p>
                  No Data
                </p>
              </div>
            </div>
          )}
        </>
        {/*  )} */}


        {/* {matches && (
          <>
            {jettons.length > 0 && (
              <>
                {
                  jettons.map((jetton, index) => (
                    <div className="mobileList">
                      <span>地址:
                      {SliceAddress(Address.parse(jetton.jetton.address).toString())}<CopyOutlined onClick={() => copyHandler(Address.parse(jetton.jetton.address).toString())}></CopyOutlined>
                      </span>
                      <span>简称:{jetton.jetton.symbol}</span>
                      <span>精度:{jetton.jetton.decimals}</span>
                      <span>余额:{Number(jetton.balance) / 10 ** (jetton.jetton.decimals)}</span>
                    </div>
                  ))
                }
              </>

            )}
          </>
        )} */}
      </ManagementList>
    </div>
  );
}

export default Management;
