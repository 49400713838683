// import { t } from 'i18next'

import { useTranslation } from 'react-i18next';
import { Question } from './style'
function App() {
const { t } = useTranslation();
  return (
    <>
      <Question>
        <h1>{t("If you do not receive the test coins in your wallet, please check whether you have switched to the test network. You can only see them on the test network.")}</h1>
        <h2>{t("If you don’t know how to switch testnets, you can check out this tutorial:")}<a style={{color:"#0cb2ff"}} rel="nofollow" href="https://docs.gtokentool.com/ton/tonkeeper-qian-bao-an-zhuang-jiao-cheng">https://docs.gtokentool.com/ton/tonkeeper-qian-bao-an-zhuang-jiao-cheng</a> </h2>
        <h3></h3>
      </Question>
    </>
  )
}
export default App
