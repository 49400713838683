import { Box, styled, Typography } from "@mui/material";

const StyledTxLoaderContent = styled(Box)({
  textAlign: "center",
  "& p": {
    fontSize: 18,
    fontWeight: 500,
  },
});

const StyledContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  gap: 30,
  [theme.breakpoints.down("md")]: {
    flexDirection: "column",
    gap: 30,
  },
}));

const StyledDescription = styled(Box)(({ theme }) => ({
  position: "relative",
  background: "#fff",
  borderRadius: 16,
  border: "0.5px solid rgba(114, 138, 150, 0.24)",
  boxShadow: "0px 2px 16px rgb(114 138 150 / 8%)",

  "& p": {
    fontSize: 16,
    lineHeight: "24px",
  },
  [theme.breakpoints.down("md")]: {
    "& p": {
      fontSize: 14,
      lineHeight: "20px",
    },
  },
}));

const ScreenHeading = styled(Typography)(({ theme }) => ({
  fontWeight: 800,
  color: "#fff",
  marginTop: "100px",
  fontFamily: "mulish, sans-serif",
  fontSize: 48,
  lineHeight: "120px",
  [theme.breakpoints.down("md")]: {
    fontSize: 28,
  },
  [theme.breakpoints.down("sm")]: {
    // marginTop: theme.spacing(8),
    marginTop: "0",
    lineHeight: "50px",
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "stretch",
  gap: theme.spacing(5),
  [theme.breakpoints.down("lg")]: {
    flexDirection: "column",
  },
}));

const SubHeadingWrapper1 = styled(Box)(({ theme }) => ({
  div:{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    div:{
      width:22,
      height:"10px",
      transform: "rotateX(180deg)",
      img:{
        width:"100%",
        aspectRatio:"22/10"
      }
    }
  },
  
  background: "rgba(54,61,70,0.5)",
  borderRadius: "10px",
  color:"#0cb1ff",
  textAlign:"center",
  boxShadow: "0px 2px 16px rgba(114, 138, 150, 0.08)",
  padding: theme.spacing(2),
  marginTop:10,
  span:{
    marginRight:10,
  },
  ul:{
    padding:0,
    width:"100%",
    img:{
      width:"100%",
      aspectRatio:"1240/731"
    }
  }
  // [theme.breakpoints.down("lg")]: {
  // marginTop:80,
  // },
  // [theme.breakpoints.down("md")]: {
  //   marginTop:50,
  //   },
  //   [theme.breakpoints.down("sm")]: {
  //     marginTop:10,
  //     }

}));
const SubHeadingWrapper = styled(Box)(({ theme }) => ({
  flex: 5,
  // background: "redF",
  background: "rgba(54,61,70,0.5)",
  borderRadius: "10px",
  boxShadow: "0px 2px 16px rgba(114, 138, 150, 0.08)",
  padding: theme.spacing(3),
  marginTop:10,
  [theme.breakpoints.down("lg")]: {
  marginTop:80,
  },
  [theme.breakpoints.down("md")]: {
    marginTop:50,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop:10,
      }

}));

const FormHeading = styled(Typography)(({ theme }) => ({
  color: "#161C28",
  fontSize: 20,
  fontWeight: 800,
  marginBottom: theme.spacing(3),
}));

export {
  StyledDescription,
  StyledContainer,
  StyledTxLoaderContent,
  ScreenHeading,
  FormWrapper,
  SubHeadingWrapper,
  FormHeading,
  SubHeadingWrapper1
};
