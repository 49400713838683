import { useRef, useState } from "react";
import { Content, CoverLay, List, Nav } from "../style";
import Wallet from "../components/Wallet";
import TestCoin from "../components/TestCoin";
import TokenCreation from "../components/TokenCreation";
import BatchTransfer from "../components/BatchTransfer";
import Ston from "../components/Ston";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();

  const title = useRef(t('Tonkeeper wallet installation tutorial'));
  const [id, setId] = useState(1)
  const handleTitleChange = (newTitle: string, id: number) => {
    console.log(newTitle, 'newTitle')
    title.current = newTitle
    setTutorial(!Tutorial)
    setId(id)
  };
  const [Tutorial, setTutorial] = useState(false);
  const options = [
    { id: 1, text: t('Tonkeeper wallet installation tutorial') },
    { id: 2, text: t('Tutorial on receiving test coins from TON') },
    { id: 3, text: t('Ton chain one-click currency issuance tutorial') },
    { id: 4, text: t('Tutorial on sending tokens in batches') },
    { id: 5, text: t('STON.fi Add Liquidity Tutorial') }
  ];
  console.log(options[id - 1].text)

  return (
    <>
      <List onClick={() => setTutorial(!Tutorial)}>
        <h1>{options[id - 1].text}</h1>
        <div className="Img">
          {!Tutorial && (
            <img src="/images/Tutorial.webp" alt="Tutorial" />
          )}
          {Tutorial && (
            <img src="/images/Tutorial(1).webp" alt="Tutorial" />
          )}

        </div>

      </List>
      <Content style={{ background: Tutorial ? "rgba(21,28,31,0.5)" : "#151c1f" }}>
        {Tutorial && (
          <Nav>
            <div className="list">
              {options.map((option) => (
                <div key={option.id} onClick={() => handleTitleChange(option.text, option.id)}>
                  {t(option.text)}
                </div>
              ))}
            </div>
          </Nav>
        )}

        <CoverLay style={{ opacity: Tutorial ? 0.5 : 1 }}>
          {id === 1 && (
            <Wallet></Wallet>
          )}
          {id === 2 && (
            <TestCoin></TestCoin>
          )}
          {id === 3 && (
            <TokenCreation></TokenCreation>
          )}
          {id === 4 && (
            <BatchTransfer></BatchTransfer>
          )}
          {id === 5 && (
            <Ston></Ston>
          )}
        </CoverLay>
      </Content>
    </>
  )
}


export default App