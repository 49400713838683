import { FluidityPage, FluidityTitle } from "./style";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";

function Fluidity() {
  const navigate = useNavigate();
  const location = useLocation();
  const tabs = [
    { key: "create", label: "创建流动性池", path: "/fluidity/create" },
    { key: "remove", label: "移除流动性", path: "/fluidity/remove" },
    { key: "burn", label: "燃烧流动性", path: "/fluidity/burn" },
  ];
  const [activeTab, setActiveTab] = useState(() => {
    const path = location.pathname.split("/").pop();
    return tabs.some((tab) => tab.key === path) ? path : "/fluidity/create";
  });

  const handleTabClick = (tab) => {
    setActiveTab(tab.key);
    navigate(tab.path);
  };

  return (
    <>
      <FluidityPage>
        <FluidityTitle>
          <div>
            {tabs.map((tab) => (
              <div
                key={tab.key}
                className={`tab ${activeTab === tab.key ? "active" : ""}`}
                onClick={() => handleTabClick(tab)}>
                {tab.label}
              </div>
            ))}
          </div>
        </FluidityTitle>
        <Outlet />
      </FluidityPage>
    </>
  );
}
export default Fluidity;
