import { Box, styled, Typography } from "@mui/material";
const CreateTitle = styled(Box)(({ theme }) => ({
  fontSize: 24,
  marginTop: 30,
  color: " #FFFFFF",
  [theme.breakpoints.down("md")]: {
    fontSize: 20,
    marginTop: 15,
  },
}));
const Page = styled(Box)(({ theme }) => ({
  marginTop: 10,
  background: "#15191D",
  borderRadius: "10px",
  padding: "40px 60px",
  [theme.breakpoints.down("md")]: {
    padding: "20px 15px",
  },
}));
const CreateText = styled(Box)(({ theme }) => ({
  fontSize: 20,
  color: " #FFFFFF",
  marginLeft: 30,
  input: {
    width: "100%",
    lineHeight: "57px",
    paddingLeft: "30px",
    fontSize: 20,
    color: "#FFF",
    borderRadius: 28,
    border: " 1px solid #808080",
    outline: "none",
    background: " #15191D",
  },
  div: {
    marginTop: 40,
    marginBottom: 10,
  },
  span: {
    lineHeight: "40px",
    fontSize: 20,
    color: " #7689AE",
    // cursor:"pointer"
  },
  "input::placeholder": {
    fontSize: 20,
    color: "#999999",
  },
  [theme.breakpoints.down("md")]: {
    marginLeft: 15,
    fontSize: 16,
    "input::placeholder": {
      fontSize: 16,
    },
    div: {
      marginTop: 20,
      marginBottom: 10,
    },
    input: {
      lineHeight: "38px",
      paddingLeft: "15px",
    },
    span: {
      lineHeight: "30px",
      fontSize: 14,
      // cursor:"pointer"
    },
  },
}));
const CreateText1 = styled(Box)(({ theme }) => ({
  fontSize: 20,
  color: " #FFFFFF",
  marginLeft: 30,
  ">div:nth-child(1)": {
    marginTop: 40,
    marginBottom: 10,
  },
  "div:nth-child(2)": {
    display: "flex",
    ".change": {
      cursor: "pointer",

      width: 108,
      background: "#666666",
      borderRadius: "28px 0px 0px 28px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      div: {
        width: 20,
        height: 20,
        img: {
          width: "100%",
          height: "100%",
        },
      },
    },
    input: {
      flex: "1",
      outline: "none",
      lineHeight: "57px",
      // paddingLeft: "30px",
      fontSize: 20,
      border: " 1px solid #808080",
      textAlign: "center",
      color: "#FFF",
      background: " #15191D",
    },
    "div:nth-child(3)": {
      borderRadius: "0px 28px 28px  0px",
    },
  },
  span: {
    lineHeight: "40px",
    fontSize: 20,
    color: " #7689AE",
    cursor: "pointer",
  },
  "input::placeholder": {
    fontSize: 20,
    color: "#999999",
    textAlign: "center",
  },
  ".percent": {
    background: "#3E3F41",
    borderRadius: 28,
    border: "1px solid #838486",
    width: 131,
    lineHeight: " 57px",
    textAlign: "center",
    color: "#E6E6E6",
    cursor: "pointer",
    marginLeft: "10px",
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
    marginLeft: 15,
    ">div:nth-child(1)": {
      marginTop: 20,
    },
    "div:nth-child(2)": {
      input: {
        lineHeight: "38px",
        paddingLeft:0,
        fontSize: 16,
        width: "30%",
      },
      ".change": {
        width: 40,
        div: {
          width: 15,
          height: 15,
        },
      },
    },
    ".percent": {
      lineHeight: "38px",
      fontSize: 14,
      width: 50,
    },
    "input::placeholder": {
      fontSize: 16,
    },
  },
}));
const CreateButton = styled(Box)(({ theme }) => ({
  width: 250,
  lineHeight: "52px",
  background: "linear-gradient(106deg, #0CB8FF 0%, #1273FB 100%)",
  borderRadius: "26px",
  fontSize: 23,
  textAlign: "center",
  color: "#FFFFFF",
  cursor: "pointer",
  margin: "80px auto",
  [theme.breakpoints.down("md")]: {
    width: 126,
    lineHeight: "38px",
    fontSize: 16,
    margin: "40px auto",
  },
}));

export { Page, CreateTitle, CreateText, CreateText1, CreateButton };
