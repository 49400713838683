import { CreateTitle, Page, CreateText, CreateText1, CreateButton } from "./style";
import { TonClient, toNano } from "@ton/ton";
import { DEX } from "@ston-fi/sdk";
const client = new TonClient({
  endpoint: "https://testnet.toncenter.com/api/v2/jsonRPC",
});

const router = client.open(new DEX.v1.Router());
console.log(router, "router");
function Create() {
  console.log(DEX, "DEX");
  const USER_WALLET_ADDRESS = "0QAdA75fp3ZYhuO9pWMsHoMD4xUbu1-MXMXtZXMlxH_urnhu"; // ! replace with your address
  const JETTON_0_ADDRESS = "EQDpCbyqoD55euFbwngXA6fpeNu3zGy4jSMqHzXTMSxuyc2F"; // TestRED
  const JETTON_1_ADDRESS = "EQDsRjMxOJjEBPsnGJH-DBz5qoLKYw7KgHtbqZdKhgUyUGUi";
  const Click = async () => {
    const txParams = await Promise.all([
      // deposit 1 TestRED to the TestRED/TestBLUE pool and get at least 1 nano LP token
      router.getProvideLiquidityJettonTxParams({
        userWalletAddress: USER_WALLET_ADDRESS,
        sendTokenAddress: JETTON_0_ADDRESS,
        sendAmount: toNano("10000"),
        otherTokenAddress: JETTON_1_ADDRESS,
        minLpOut: "1",
        queryId: 12345,
      }),
      // deposit 1 TestBLUE to the TestRED/TestBLUE pool and get at least 1 nano LP token
      router.getProvideLiquidityJettonTxParams({
        userWalletAddress: USER_WALLET_ADDRESS,
        sendTokenAddress: JETTON_1_ADDRESS,
        sendAmount: toNano("100000"),
        otherTokenAddress: JETTON_0_ADDRESS,
        minLpOut: "1",
        queryId: 123456,
      }),
    ]);
    console.log(txParams, "txParams");
  };
  return (
    <>
      <Page>
        <CreateTitle>增加流动性</CreateTitle>
        <CreateText>
          <div onClick={Click}>基础代币：</div>
          <input type="text" placeholder="请选择基础代币" />
          <span>找不到币种，试试自定义</span>
        </CreateText>
        <CreateText>
          <div>报价代币：</div>
          <input type="text" placeholder="请选择报价代币" />
          <span>找不到币种，试试自定义</span>
        </CreateText>
        <CreateTitle>增加流动性</CreateTitle>
        <CreateText1>
          <div>基础代币数量：</div>
          <div>
            <div className="change">
              <div>
                <img src="/images/reduce.png" alt="reduce" />
              </div>
            </div>
            <input type="text" placeholder="请输入基础代币数量" />
            <div className="change">
              <div>
                <img src="/images/plus1.png" alt="plus1" />
              </div>
            </div>
            <div className="percent">50%</div>
            <div className="percent">100%</div>
          </div>
        </CreateText1>
        <CreateText1>
          <div>报价代币数量：</div>
          <div>
            <div className="change">
              <div>
                <img src="/images/reduce.png" alt="reduce" />
              </div>
            </div>
            <input type="text" placeholder="请输入报价代币数量" />
            <div className="change">
              <div>
                <img src="/images/plus1.png" alt="plus1" />
              </div>
            </div>
            <div className="percent">50%</div>
            <div className="percent">100%</div>
          </div>
        </CreateText1>
        <CreateButton>创建</CreateButton>
      </Page>
    </>
  );
}
export default Create;
