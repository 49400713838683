import { Box, styled, Typography } from "@mui/material";
const Search = styled(Box)(({ theme }) => ({
  marginTop: 50,
  display: "flex",
  width: "100%",
  input: {
    borderRadius: "28px 0px 0px 28px",
    width: "calc(100% - 230px)",
    lineHeight: "65px",
    border: "1px solid #808080",
    color: "#FFF",
    fontSize: 20,
    outline: "none",
    background: "#161A1D",
    paddingLeft: "30px",
  },
  span: {
    fontSize: 20,
    width: 230,
    lineHeight: "65px",
    borderRadius: " 0px 28px 28px 0px",
    textAlign: "center",
    background: " #666666",
    cursor: "pointer",
    color: "#E6E6E6",
  },
  [theme.breakpoints.down("md")]: {
    input: {
      width: "calc(100% - 100px)",
      lineHeight: "38px",
    },
    span: {
      fontSize: 16,
      lineHeight: "38px",
      width: 100,
    },

    // width: 126,
    // lineHeight: "38px",
    // fontSize: 16,
    // margin: "40px auto",
  },
}));
const Text = styled(Box)(({ theme }) => ({
  fontSize: 20,
  color: "#7689AE",
  span: {
    fontWeight: "700",
    lineHeight: 4,
  },
  [theme.breakpoints.down("md")]: {
    fontSize: 16,
  }
}));

export { Search, Text };
