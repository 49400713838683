import {CreateTitle,Page} from '../Create/style'
import { Search,Text } from "../Remove/style";

function Burn(){
  return(
    <>
    <Page>
        <Search>
          <input type="text" />
          <span>搜索</span>
        </Search>
        <Text>不知道池子ID，试试 <span>代币地址查找</span></Text>
      </Page>
    </>
  )
}
export default Burn