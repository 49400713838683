import { Box, styled, Typography } from "@mui/material";
// import styled from 'styled-components'
export { ManagementList };
const ManagementList = styled(Box)(({ theme }) => ({
  background: "#15191D",
  borderRadius: 6,
  color: "#FFF",
  fontSize: 20,
  padding: 20,
  textAlign: "center",
  ".title": {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "65px",
    borderBottom: "1px solid #4d4d4d",
    span: {
      display: "flex",
      width: "25%",
      justifyContent: "center",
    },
  },
  ".list": {
    display: "flex",
    justifyContent: "space-between",
    lineHeight: "65px",
    borderBottom: "1px solid #4d4d4d",
    span: {
      display: "flex",
      width: "25%",
      justifyContent: "center",
    },
  },
  ".list:last-child": {
    border: "none",
  },
  ".list:hover": {
    background: "#4d4d4d",
    cursor: "pointer",
  },
  ".noData": {
    height: "50vh",
    background: "#15191D",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgb(140, 140, 140)",
    fontSize: "16px",
  },
  ".mobileList": {
    background: "red",
  },
  [theme.breakpoints.down("md")]: {
    // display: "none",
    fontSize: 16,
    overflow: "hidden",
  },
}));
export const PaginationSwapper = styled(Box)`
  height: 76px;
  //   margin: 10px 40px 0;
  border-radius: 4px;

  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;

    img {
      width: 21px;
      height: 21px;
    }
  }

  .ant-pagination-next .ant-pagination-item-link {
    background-color: #242424;
    color: #cccccc;
    border: none;
  }
  .ant-pagination-item {
    background-color: #242424;
    color: #cccccc;
    border: none;
  }
  .ant-pagination-item a {
    color: #cccccc;
  }
  .ant-pagination-item-active {
    background-color: #021a29;
  }
  .ant-pagination-item-active a {
    color:  #0CB8FF;
  }
  .ant-pagination-prev .ant-pagination-item-link {
    background-color: #242424;
    color: #cccccc;
    border: none;
  }
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #cccccc;
  }
  .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis {
    color: #cccccc;
  }
  .ant-pagination-options {
    display: none;
  }

  @media (max-width: 768px) {
    width: 98%;
  }
`;
