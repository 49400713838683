import { useMediaQuery } from "@mui/material";
import { t } from "i18next"

function App() {
  const matches = useMediaQuery("(max-width:900px)");

  return (
    <>
      <div className="images">
        <iframe
          src="https://www.youtube.com/embed/O-lJQJFjF0A"
          title="Inline Frame Example"
          width="100%"
          height={matches ? "200" : "450"}
          style={{ border: "none"}}
        >
        </iframe>
      </div>

      <h2>1.{t("Click to connect wallet")}</h2>
      <p>{t("Fabi official website")} <a href="https://ton.gtokentool.com/">https://ton.gtokentool.com/</a></p>
      <div className="images">
        <img src="/images/connect.webp" alt="tokenCreation" />
      </div>
      <h2>2.{t("Fill in the token information")}</h2>
      <p><b>Logo</b>：{t("Token avatar, which can display the logo token image in the wallet")}</p>
      <p><b>{t("Jetton Name")}</b>：{t("The name information of the token (such as gggToken) supports English, Chinese, and a mixture of Chinese and English, with a maximum of 15 characters.")}</p>
      <p><b>{t("Symbol")}</b>：{t("The abbreviation information of the token (such as GGG) supports English, Chinese, and a mixture of Chinese and English, with a maximum of 15 characters.")}</p>
      <p><b>{t("Jetton Decimals")}</b>：{t("The number of digits of precision for the token, which defaults to 9. The precision is related to the maximum total supply you can fill.")}</p>
      <p><b>{t("Total Supply")}</b>：{t("The total supply of tokens. When the accuracy is 9, the total supply cannot exceed 10 billion; when the accuracy is 8, the total supply cannot exceed 100 billion, and so on.")}</p>
      <p><b>{t("Description")}</b>：{t("Introduction to Tokens")}</p>
       <div className="images">
        <img src="/images/create1.webp" alt="tokenCreation" />
      </div>
      <h2>3.{t("Click 'Configure' to create tokens")}</h2>
      <p>{t("After confirming the information, click the 'Confirm Creation' button, and a wallet prompt will pop up. Click 'Confirm' to pay the fee to complete the creation.")}</p>
      <div className="images">
        <img src="/images/create.webp" alt="tokenCreation" />
      </div>
      <h3></h3>

    </>
  )
}
export default App