import { IconButton, useMediaQuery } from "@mui/material";
import { Box } from "@mui/system";
import { useCallback, useEffect, useRef, useState } from "react";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import { HeaderMenu, MobileMenu } from "components/header/headerMenu/HeaderMenu";
import { useTranslation } from 'react-i18next';
import { AppLogo } from "components/appLogo";
import LanguageSwitcher from '../LanguageSwitcher';
import { SearchBar } from "components/header/headerSearchBar";
import {
  HeaderContent,
  HeaderOptionalContent,
  HeaderWrapper,
  Nav,
  List,
} from "./styled";
import { ROUTES } from "consts";

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useNetwork } from "lib/hooks/useNetwork";
import Navigation from "components/Navigation";

export const Header = () => {
  const { t } = useTranslation();

  const [mobileMenu, setMobileMenu] = useState(false);
  const matches = useMediaQuery("(min-width:900px)");
  const matches1 = useMediaQuery("(min-width:1200px)");
  const [example, setExample] = useState<string | undefined>(undefined);
  const { network } = useNetwork();

  const location = useLocation();
  const navigate = useNavigate()
  const topRef = useRef<null | HTMLDivElement>(null);

  const resetExample = useCallback(() => {
    setExample(undefined);
  }, []);

  useEffect(() => {
    topRef.current?.scrollIntoView();
  }, [location]);
  const [nav, setNav] = useState(false);

  const isNav = () => {
    console.log(nav)
    setNav(!nav);
  }
  const handleClick = () => {
    setMobileMenu(true)
    isNav()
  }
  return (
    <>
      <HeaderWrapper ref={topRef}>
        <HeaderContent>
          <HeaderOptionalContent>
            <div style={{ display: "flex" }} onClick={() => window.location.href = 'https://ton.gtokentool.com/'}>
              <AppLogo />

            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              <a rel="nofollow" style={{ fontSize: matches ? "20px" : "16px", color: "#FFF", textDecoration: "none" }} href="https://t.me/+zB3WXjGkOScwMDM1">{t("contact us")}</a>
              <LanguageSwitcher></LanguageSwitcher>
              <HeaderMenu />
              {!matches1 && (
                <IconButton style={{ padding: "0" }} onClick={handleClick}>
                  <MenuRoundedIcon style={{ width: 40, height: 40, color: "#50A7EA" }} />
                </IconButton>)}
              <List style={{ display: nav ? "flex" : "none", }}>
                <div className="left" style={{ width: "30vw", height: "100vh", background: " rgba(97, 96, 101, 0.5)" }} onClick={isNav}></div>
                <Navigation isNav={isNav} ></Navigation>
              </List>


            </div>

          </HeaderOptionalContent>

          {/* <MobileMenu showMenu={mobileMenu && !matches} closeMenu={() => setMobileMenu(false)} /> */}
        </HeaderContent>
        <Nav>
          <div onClick={() => navigate(ROUTES.deployer)} >
            {t("Create")}
          </div>
          <div onClick={() => navigate('management')}>
            {t("Management")}
          </div>
          <div onClick={() => navigate('/marketValue')}>
            {t("Market value(development...)")}
          </div>
          <div onClick={() => navigate('/send')}>
            {"批量工具"}
          </div>
         
          <div onClick={() => navigate('/fluidity/create')}>
            {t("Liquidity(development...)")}
          </div>
          <div onClick={() => navigate('/docs')}>
            {t("Help documentation")}
          </div>
          {/* <div onClick={() => navigate('/docs')}>
            ton学习(开发中)
          </div> */}
        </Nav>
      </HeaderWrapper>

      <Outlet />
    </>
  );
};

