import { Box, styled, Typography } from "@mui/material";

export const DocsPage = styled(Box)(({ theme }) => ({
  paddingTop: 150,
  color: "#FFF",
  [theme.breakpoints.down("sm")]: {
    paddingTop: 50,

  },
}))

export const Title = styled(Box)(({ theme }) => ({
  background: "#000707",
  justifyContent: "flex-start",
  display: "flex",
  fontSize: 20,

  span: {
    lineHeight: "50px",
    margin: "0 32px"
  },
  p: {
    height: 24,
    width: 1,
    background: "#1e2324"
  },
  ".clickable": {
    cursor: "pointer",
  }
  ,
  ".underline": {
    borderBottom: "3px solid #0cb2ff"
  },
  [theme.breakpoints.down("sm")]: {
    justifyContent: "space-around",
    span: {
      lineHeight: "50px",
      textAlign: "center",
      fontSize: 15,
    },
  }

}))
export const List = styled(Box)(({ theme }) => ({
  lineHeight: "110px",
  padding: "0 16px",
  fontWeight: 700,
  background: "#23292f",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid #2c3338",
  h1: {
    fontSize: 30,
    margin: 0,
    cursor: "pointer"
  },
  ".Img": {
    width: 32,
    height: 16,
    img: {
      width: "100%",
      aspectRatio: "16/8"
    }
  },
  [theme.breakpoints.down("sm")]: {
    lineHeight: "55px",
    h1: {
      fontSize: 20,
    },
    ".Img": {
      width: 16,
      height: 8,
    },
  }
}))
export const Content = styled(Box)(({ theme }) => ({
  background: "#151c1f",
  overflow: "hidden",
  position: "relative",
  fontSize: 16,
  padding: "0 32px 80px",
  // minHeight:"60vh",
  h2: {
    fontSize: 20,
    fontWeight: 700,
    marginTop: 34,

  },
  p: {
    lineHeight: "24px",
  },
  a: {
    color: "#0cb2ff",
    wordBreak: "break-all"
  },
  ".images": {
    maxWidth:767,
    margin:"12px auto",
    img:{
      width: "auto",
      height: "auto",
      maxWidth:"100%",
      maxHeight:"100%",
      margin:"0 auto"
    }
  },
  ".especial":{
    maxWidth:320,
    margin:"0 auto"
  },
  [theme.breakpoints.down("sm")]: {
    padding: "0 16px 40px",

    h2: {
      fontSize: 15,
    },
    "p,h3,span": {
      fontSize: 14,
    },
  }

}))
export const Nav = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  opacity: 1,
  zIndex: 99,
  margin: 0,
  ".list": {
    padding: "20px 16px",
    background: "#151c1f",
    div: {
      margin: "30px 0"
    }
  },
  ".list >div": {
    cursor: "pointer",
    fontSize: 20,
    


  },
  [theme.breakpoints.down("sm")]: {
    ".list >div": {
      fontSize: 16



    },
  }

}))

export const CoverLay = styled(Box)({
  margin: 0,
  minHeight: "60vh"
})