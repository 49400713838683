import { t } from "i18next"

function App() {
  return (
    <>

      <h2>ston{t("website")}：<a rel="nofollow" href="https://app.ston.fi/swap">https://app.ston.fi/swap</a> </h2>
      <h3></h3>
    </>
  )
}
export default App