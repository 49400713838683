
import { Box, styled, Typography } from "@mui/material";

export const Question = styled(Box)({
  background: " rgb(21, 28, 31)",
  padding: "50px 16px",
  minHeight:"60vh",
  "h1,h2": {
    fontSize: 16
  }

})