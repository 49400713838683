import React, { useState } from 'react';
import { Button, Modal, Input } from 'antd';

interface PropsType {
  callBack: (amount: string) => void
}

const App = (props: PropsType) => {
  const { callBack } = props
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [value, setValue] = useState('')

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    callBack(value)
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Button type="primary" onClick={showModal}>
        自动添加数量
      </Button>
      <Modal title="自动添加数量" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
        <Input value={value} onChange={(e) => setValue(e.target.value)} />
      </Modal>
    </>
  );
};

export default App;