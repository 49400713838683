import { useState } from "react";
import { DocsPage, Title } from "./style"
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function App() {
  const { t } = useTranslation();
  const navigate = useNavigatePreserveQuery();
  const tabs = [
    { key: 'tutorial', label: "Operation tutorial", path: '/docs/doc' },
    { key: 'question', label: "common problem", path: '/docs/question' },
  ];
  const location = useLocation();
  const [selected, setActiveTab] = useState(() => {
    const path = location.pathname.split('/').pop();
    return tabs.some(tab => tab.key === path) ? path : 'tutorial';
  });

  function navHandle(id: string) {
    if (id === "tutorial") {
      navigate("/docs/doc")
    } else {
      navigate("/docs/question")
    }
    setActiveTab(id)

  }


  return (
    <>
      <DocsPage>
        <Title>
          <span id="tutorial"
            className={`clickable ${selected === 'tutorial' ? 'underline' : ''}`}
            onClick={() => navHandle("tutorial")}>{t("Operation tutorial")}</span>
          <p></p>
          <span id="question"
            className={`clickable ${selected === 'question' ? 'underline' : ''}`}
            onClick={() => navHandle("question")}>{t("common problem")}</span>
        </Title>
        <Outlet />
      </DocsPage>
    </>
  )
}

export default App